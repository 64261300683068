<template>
<b-field :horizontal="isMobile()" :class="[customClass, 'mb-0']">
    <b-datepicker
        v-if="rangeDateEnabled"
        style="min-width: 16rem;"
        class="form-control"
        placeholder="Date..."
        v-model="selectedRangeDate"
        range>
      <b-button
          label="Yesterday"
          type="is-primary"
          icon-left="calendar"
          @click="setSelectedDates('YESTERDAY', 'selectedRangeDate')"/>
      <b-button
          label="Last 7 days"
          type="is-primary"
          icon-left="calendar"
          @click="setSelectedDates('LAST_7_DAYS','selectedRangeDate')"/>
      <b-button
          label="This Month"
          type="is-primary"
          icon-left="calendar-today"
          @click="setSelectedDates('THIS_MONTH','selectedRangeDate')"/>
      <b-button
          label="Previous Month"
          type="is-primary"
          icon-left="calendar"
          @click="setSelectedDates('PREVIOUS_MONTH','selectedRangeDate')"/>
    </b-datepicker>
  <b-datepicker
      v-if="singleDateEnabled"
      class="form-control"
      style="min-width: 8rem;"
      placeholder="Date..."
      v-model="selectedDate">
      <b-button
      label="Today"
      type="is-primary"
      icon-left="calendar-today"
      @click="setSelectedDate('TODAY')" />
      <b-button
      label="Yesterday"
      type="is-primary"
      icon-left="calendar"
      @click="setSelectedDate('YESTERDAY')" />
  </b-datepicker>

  <slot name="left"></slot>

  <multiselect
      v-if="networksEnabled"
      v-model="selectedNetworks"
      @input="selectedNetworksChanged"
      :options="setGroup(networksFiltered, 'networks', singleNetwork)"
      :group-label="singleNetwork ? '' : 'label'"
      :group-values="singleNetwork ? '': 'all'"
      :group-select="!singleNetwork"
      @remove="(e) => unselectGroup(e,'selectedNetworks', singleNetwork)"
      :multiple="!singleNetwork"
      :loading="networks.loading"
      :close-on-select="true"
      :placeholder="singleNetwork ? 'Network' : 'Networks'"
      label="name"
      :searchable="true"
      track-by="id">
  </multiselect>
  <multiselect
      v-if="availableNetworksEnabled"
      v-model="selectedAvailableNetworks"
      @input="selectedNetworksChanged"
      :options="setGroup(networksFiltered, 'networks', singleNetwork)"
      :group-label="singleNetwork ? '' : 'label'"
      :group-values="singleNetwork ? '': 'all'"
      :group-select="!singleNetwork"
      @remove="(e) => unselectGroup(e,'selectedNetworks', singleNetwork)"
      :multiple="!singleNetwork"
      :loading="networks.loading"
      :close-on-select="true"
      :placeholder="singleNetwork ? 'Network' : 'Networks'"
      label="name"
      :searchable="true"
      track-by="id">
  </multiselect>
  <multiselect
      v-if="subdomainLocalesEnabled && customizeForPlusModal"
      v-model="selectedSubdomainLocales"
      @input="selectedSubdomainLocalesChanged"
      :options="setGroup(subdomainLocalesFiltered, 'subdomainLocales')"
      group-label="label"
      group-values="all"
      :group-select="true"
      @remove="(e) => unselectGroup(e, 'selectedSubdomainLocales')"
      :multiple="!singleSubdomainLocales"
      :loading="subdomainLocales.loading"
      :close-on-select="true"
      :placeholder="customizeForPlusModal ? 'Countries': singleSubdomainLocales ? 'Subdomain Locale' : 'Subdomain Locales'"
      :label="customizeForPlusModal ? 'country_name' : 'custom_name'"
      :track-by="customizeForPlusModal ? 'country_id' : 'id'">
  </multiselect>
  <multiselect
      v-if="agencyFeedsEnabled"
      v-model="selectedAgencyFeeds"
      @input="selectedAgencyFeedsChanged"
      :options="setGroup(agencyFeeds.all, 'agencyFeeds')"
      group-label="label"
      group-values="all"
      :group-select="true"
      @remove="(e) => unselectGroup(e, 'selectedAgencyFeeds')"
      :multiple="true"
      :close-on-select="true"
      :loading="agencyFeeds.loading"
      placeholder="Agency Feeds"
      :label="getFieldCodeLabel()"
      track-by="id">
  </multiselect>
  <multiselect
      v-if="marketingAgencyEnabled && getUserGroups().some(r => ['IsSuperUser', 'IsBusinessDeveloper'].includes(r))"
      v-model="selectedMarketingAgency"
      @input="trackSelectLabel['marketingAgency'] = internalAgencyManager.all.length !== selectedMarketingAgency.length"
      :options="setGroup(internalAgencyManager.all, 'marketingAgency')"
      group-label="label"
      group-values="all"
      :group-select="true"
      @remove="(e) => unselectGroup(e, 'selectedMarketingAgency')"
      :multiple="true"
      :close-on-select="true"
      :loading="internalAgencyManager.loading"
      placeholder="Marketing Agency"
      :custom-label="e => e.organization.name"
      track-by="id">
  </multiselect>
  <multiselect
      v-if="partnersEnabled"
      v-model="selectedPartners"
      @input="selectedPartnersChanged"
      :options="setGroup(partners.all, 'partners')"
      group-label="label"
      group-values="all"
      :group-select="true"
      @remove="(e) => unselectGroup(e, 'selectedPartners')"
      :multiple="!singlePartner"
      :close-on-select="true"
      :loading="partners.loading"
      :placeholder="singlePartner ? 'Partner': 'Partners'"
      :custom-label="r => `${r[getFieldCodeLabel()]}`"
      track-by="id">
  </multiselect>
  <multiselect
      v-if="partnersAvailableEnabled"
      v-model="selectedAvailablePartners"
      @input="selectedPartnersChanged"
      :options="setGroup(availablePartnersFiltered, 'partners')"
      group-label="label"
      group-values="all"
      :group-select="true"
      @remove="(e) => unselectGroup(e, 'selectedAvailablePartners')"
      :multiple="!singlePartner"
      :close-on-select="true"
      :loading="partners.loading"
      :placeholder="singlePartner ? 'Partner': 'Partners'"
      :custom-label="r => `${r[getFieldCodeLabel()]}`"
      track-by="id">
  </multiselect>
  <multiselect
      v-if="productsEnabled"
      v-model="selectedProducts"
      @input="selectedProductsChanged"
      :options="setGroup(products.all, 'products')"
      group-label="label"
      group-values="all"
      :group-select="true"
      @remove="(e) => unselectGroup(e, 'selectedProducts')"
      :multiple="!singleProduct"
      :close-on-select="true"
      :loading="products.loading"
      :placeholder="productPlaceholder()"
      :label="getFieldNameLabel()"
      track-by="id">
  </multiselect>
  <multiselect
      v-if="productsWithAvailableUrlCodesEnabled"
      v-model="selectedProductsWithAvailableUrlCodes"
      @input="selectedProductsWithAvailableCodesChanged"
      :options="setGroup(productsWithAvailableUrlCodesFiltered, 'productsWithAvailableCodes')"
      group-label="label"
      group-values="all"
      :group-select="true"
      @remove="(e) => unselectGroup(e, 'selectedProductsWithAvailableUrlCodes')"
      :multiple="!singleProduct"
      :close-on-select="true"
      :loading="products.loading"
      :placeholder="productPlaceholder()"
      :label="getFieldNameLabel()"
      track-by="id">
  </multiselect>
  <multiselect
      v-if="countriesEnabled"
      v-model="selectedCountries"
      :options="setGroup(countries.all, 'countries')"
      group-label="label"
      group-values="all"
      :group-select="true"
      @input="selectedCountriesChanged"
      @remove="(e) => unselectGroup(e, 'selectedCountries')"
      :loading="countries.loading"
      :multiple="true"
      :close-on-select="true"
      placeholder="Countries"
      label="name"
      track-by="id">
  </multiselect>
  <multiselect
      v-if="accountsEnabled && isSuperUser()"
      v-model="selectedAccounts"
      :options="setGroup(accounts.all, 'accounts')"
      group-label="label"
      group-values="all"
      :group-select="true"
      @remove="(e) => unselectGroup(e, 'selectedAccounts')"
      @input="trackSelectLabel['accounts'] = accounts.all.length !== selectedAccounts.length"
      :multiple="true"
      :close-on-select="true"
      placeholder="Accounts"
      label="name"
      track-by="id">
  </multiselect>
  <multiselect
      v-if="biddingStrategiesEnabled"
      v-model="selectedBiddingStrategies"
      :options="setGroup(filterBiddingStrategyByNetworks(biddingStrategies.all), 'biddingStrategies')"
      group-label="label"
      group-values="all"
      :group-select="true"
      @remove="(e) => unselectGroup(e, 'selectedBiddingStrategies')"
      @input="trackSelectLabel['biddingStrategies'] = biddingStrategies.all.length !== selectedBiddingStrategies.length"
      :multiple="true"
      :close-on-select="true"
      placeholder="Bidding Strategy"
      label="code"
      track-by="id">
  </multiselect>
  <multiselect
      v-if="currenciesEnabled"
      v-model="selectedCurrencies"
      :options="setGroup(currencies.all, 'currencies')"
      group-label="label"
      group-values="all"
      :group-select="true"
      @remove="(e) => unselectGroup(e, 'selectedCurrencies')"
      @input="trackSelectLabel['currencies'] = currencies.all.length !== selectedCurrencies.length"
      :multiple="true"
      :close-on-select="true"
      placeholder="Currency">
  </multiselect>
  <multiselect
      v-if="marketingUsersEnabled"
      v-model="selectedMarketingUsers"
      :options="setGroup(users.allMarketing, 'usersMarketing')"
      group-label="label"
      group-values="all"
      :group-select="true"
      @remove="unselectGroup('selectedMarketingUsers')"
      @input="trackSelectLabel['usersMarketing'] = users.allMarketing.length !== selectedMarketingUsers.length"
      :multiple="true"
      :close-on-select="true"
      placeholder="Users"
      label="username"
      track-by="id">
  </multiselect>
  <multiselect
    v-if="adunitsEnabled"
    v-model="selectedAdunits"
    :options="setGroup(adunitsFiltered, 'adunits')"
    group-label="label"
    group-values="all"
    :group-select="true"
    @remove="(e) => unselectGroup(e, 'selectedAdunits')"
    @input="selectedAdunitsChanged"
    :multiple="true"
    :loading="adunits.loading"
    :close-on-select="true"
    placeholder="Adunits"
    label="id"
    track-by="id">
  </multiselect>
  <multiselect
      v-if="languagesEnabled"
      v-model="selectedLanguages"
      :options="setGroup(languages.all, 'languages')"
      group-label="label"
      group-values="all"
      :group-select="true"
      @remove="(e) => unselectGroup(e, 'selectedLanguages')"
      @input="selectedLanguagesChanged"
      :multiple="true"
      :close-on-select="true"
      :placeholder="optionalLanguage ? 'Languages (optional)' : 'Languages'"
      label="name"
      track-by="id">
  </multiselect>

  <multiselect
      v-if="languageFromSubdomainLocalesEnabled"
      v-model="selectedLanguagesFromSubdomainLocales"
      @input="languagesFromSubdomainLocalesChanged"
      :options="setGroup(languageFromSubdomainLocalesFiltered, 'languagesFromSubdomainLocales')"
      group-label="label"
      group-values="all"
      :group-select="true"
      @remove="(e) => unselectGroup(e, 'selectedLanguagesFromSubdomainLocales')"
      :multiple="true"
      :loading="subdomainLocales.loading"
      :close-on-select="true"
      :placeholder="optionalLanguage ? 'Languages (optional)' : 'Languages'"
      label="name"
      track-by="id">
  </multiselect>
  <multiselect
      v-if="subdomainLocalesEnabled && !customizeForPlusModal"
      v-model="selectedSubdomainLocales"
      @input="selectedSubdomainLocalesChanged"
      :options="setGroup(subdomainLocalesFiltered, 'subdomainLocales')"
      group-label="label"
      group-values="all"
      :group-select="true"
      @remove="(e) => unselectGroup(e, 'selectedSubdomainLocales')"
      :multiple="!singleSubdomainLocales"
      :loading="subdomainLocales.loading"
      :close-on-select="true"
      :placeholder="customizeForPlusModal ? 'Countries': singleSubdomainLocales ? 'Subdomain Locale' : 'Subdomain Locales'"
      :label="customizeForPlusModal ? 'country_name' : 'custom_name'"
      :track-by="customizeForPlusModal ? 'country_id' : 'id'">
  </multiselect>
  <slot></slot>

  <p class="control" v-if="!filterDisabled">
    <b-button type="is-primary" @click="filter()" @keyup.enter="filter()">Filter!</b-button>
  </p>
  <p class="control" v-if="downloadEnabled">
    <b-button type="is-warning" icon-left="download" @click="download()"></b-button>
  </p>

</b-field>
</template>

<script>
import {timeUtils} from '@/helpers/time-utils';

import {osUtils} from "@/helpers/os-utils";
import {mapState} from "vuex";
import Multiselect from 'vue-multiselect'
import moment from "moment-timezone";
import {permissionsUtils} from "@/helpers/permissions-utils";
import Vue from 'vue';

export default {

  name: 'DependantMultiFilter',

  mixins: [
      timeUtils,
      osUtils,
      permissionsUtils
  ],

  props: {
    customClass: {
      type: String,
    },
    singleDateEnabled: {
      type: Boolean,
      default: false
    },
    rangeDateEnabled: {
      type: Boolean,
      default: false
    },
    defaultRangeDate: {
      type: Array,
      default: () => [new Date(), new Date()]
    },
    networksEnabled: {
      type: Boolean,
      default: false
    },
    availableNetworksEnabled: {
      type: Boolean,
      default: false,
    },
    agencyFeedsEnabled: {
      type: Boolean,
      default: false
    },
    productsEnabled: {
      type: Boolean,
      default: false
    },
    partnersEnabled: {
      type: Boolean,
      default: false
    },
    partnersAvailableEnabled: {
      type: Boolean,
      default: false
    },
    productsWithAvailableUrlCodesEnabled: {
      type: Boolean,
      default: false
    },
    countriesEnabled: {
      type: Boolean,
      default: false
    },
    accountsEnabled: {
      type: Boolean,
      default: false
    },
    biddingStrategiesEnabled: {
      type: Boolean,
      default: false
    },
    currenciesEnabled: {
      type: Boolean,
      default: false
    },
    marketingUsersEnabled: {
      type: Boolean,
      default: false
    },
    languagesEnabled: {
      type: Boolean,
      default: false
    },
    subdomainLocalesEnabled: {
      type: Boolean,
      default: false
    },
    downloadEnabled: {
      type: Boolean,
      default: false
    },
    filterDisabled: {
      type: Boolean,
      default: false
    },
    singleNetwork: {
      type: Boolean,
      default: false,
    },
    singlePartner: {
      type: Boolean,
      default: false,
    },
    singleProduct: {
      type: Boolean,
      default: false,
    },
    singleSubdomainLocales: {
      type: Boolean,
      default: false,
    },
    customizeForPlusModal: {
      type: Boolean,
      default: false,
    },
    marketingAgencyEnabled: {
      type: Boolean,
      default: false,
    },
    languageFromSubdomainLocalesEnabled: {
      type: Boolean,
      default: false,
    },
    adunitsEnabled: {
      type: Boolean,
      default: false,
    },
    optionalProduct: {
      type: Boolean,
      default: false,
    },
    optionalLanguage: {
      type: Boolean,
      default: false,
    }
  },

  components: {
    Multiselect
  },

  mounted() {
    if(this.networksEnabled)
      if(!this.networks.all.length) this.$store.dispatch('networks/getAll');

    if(this.availableNetworksEnabled)
      if(!this.networks.allAvailableNetworks.length) this.$store.dispatch('networks/getAllAvailableNetworks');

    if(this.productsEnabled)
      if(!this.products.all.length) this.$store.dispatch('products/getAll');

    if(this.partnersEnabled)
      if(!this.partners.all.length) this.$store.dispatch('partners/getAll');

    if(this.partnersAvailableEnabled)
      if(!this.partners.allAvailablePartners.length) this.$store.dispatch('partners/getAllAvailablePartners');

    if(this.productsWithAvailableUrlCodesEnabled)
      if(!this.products.allProductsWithAvailableUrlCodes.length) this.$store.dispatch('products/getAllProductsWithAvailableUrlCodes');

    if(this.agencyFeedsEnabled)
      if(!this.agencyFeeds.all.length) this.$store.dispatch('agencyFeeds/getAll');

    if(this.countriesEnabled)
      if(!this.countries.all.length) this.$store.dispatch('countries/getAll');

    if(this.accountsEnabled && this.isSuperUser())
      if(!this.accounts.all.length) this.$store.dispatch('accounts/getAll');

    if(this.biddingStrategiesEnabled)
      if(!this.biddingStrategies.all.length) this.$store.dispatch('biddingStrategies/getAll');

    if(this.marketingUsersEnabled) {
        if (!this.users.allMarketing.length) this.$store.dispatch('users/getAllMarketing');
        this.preselectMe()
    }

    if(this.languagesEnabled)
      if(!this.languages.all.length) this.$store.dispatch('languages/getAll');

    if(this.subdomainLocalesEnabled)
      if(!this.subdomainLocales.all.length) this.$store.dispatch('subdomainLocales/getAll');

    if(this.currenciesEnabled)
      if(!this.currencies.all.length) this.$store.dispatch('currencies/getAll');

    if(this.rangeDateEnabled)
      this.selectedRangeDate = this.defaultRangeDate;

    if(this.marketingAgencyEnabled)
      if(!this.internalAgencyManager.all.length) this.$store.dispatch('internalAgencyManager/getAll')

    if(this.singleDateEnabled)
      this.preselectDate();

    if(this.languageFromSubdomainLocalesEnabled)
      if(!this.subdomainLocales.baseLanguages.length) this.$store.dispatch('subdomainLocales/getBaseLanguages');

    if(this.adunitsEnabled)
      if(!this.adunits.all.length) this.$store.dispatch('adunits/getAll');
  },

  data() {
    return {
      selectedDate: new Date(),
      selectedRangeDate: [new Date(),new Date()],

      selectedNetworks: [],
      selectedAvailableNetworks: [],
      selectedAgencyFeeds: [],
      selectedProducts: [],
      selectedPartners: [],
      selectedAvailablePartners: [],
      selectedProductsWithAvailableUrlCodes: [],
      selectedCountries: [],
      selectedAccounts: [],
      selectedBiddingStrategies: [],
      selectedCurrencies: [],
      selectedMarketingUsers: [],
      selectedLanguages: [],
      selectedSubdomainLocales: [],
      trackSelectLabel: {},
      selectedMarketingAgency: [],
      selectedAdunits: [],
      selectedLanguagesFromSubdomainLocales: [],
    }
  },

  computed: {
    ...mapState([
      'networks',
      'agencyFeeds',
      'products',
      'accounts',
      'performances',
      'hourlyPerformances',
      'countries',
      'biddingStrategies',
      'users',
      'languages',
      'subdomainLocales',
      'theme',
      'partners',
      'currencies',
      'internalAgencyManager',
      'adunits',
    ]),

    networksFiltered(){
      if(this.availableNetworksEnabled) return this.networks.allAvailableNetworks.map(n => n.network)
      return this.networks.all
    },

    productsWithAvailableUrlCodesFiltered() {
      const productList = this.products.allProductsWithAvailableUrlCodes.map(p => p.product)
      this.removeItemWhenNotInList(this.products.loading, 'selectedProductsWithAvailableUrlCodes', productList, 'selectedProductsWithAvailableCodesChanged')
      return productList
    },

    availablePartnersFiltered() {
      const partners = this.partners.allAvailablePartners.map(p => p.partner)
      this.removeItemWhenNotInList(this.partners.loading, 'selectedAvailablePartners', partners, 'selectedPartnersChanged')
      return partners
    },

    subdomainLocalesFiltered() {
      let data =  this.subdomainLocales.all.map(s => {
        let res = {
          id: s.subdomain_locale.id,
          custom_name: s.subdomain_locale.country.code + ' - ' + s.subdomain_locale.language.code + ' - ' + s.subdomain_locale.product[this.getFieldCodeLabel()],
          country_id: s.subdomain_locale.country.id,
          country_code: s.subdomain_locale.country.code,
          country_name: s.subdomain_locale.country.name,
          language_id: s.subdomain_locale.language.id,
          language_code: s.subdomain_locale.language.code,
          language_name: s.subdomain_locale.language.name,
          product_code: s.subdomain_locale.product[this.getFieldCodeLabel()],
        }

        if (this.customizeForPlusModal) {
          res = {
            country_id: s.subdomain_locale.country.id,
            country_code: s.subdomain_locale.country.code,
            country_name: s.subdomain_locale.country.name,
          }
        }
        return res
      })

      if(this.customizeForPlusModal) {
        //make a unique list of countries
        let a = []
        for(const key in data) {a['_'+data[key]['country_id']] = data[key]}
        let aa = []
        for (const key in a) {aa.push(a[key])}
        data = aa
      }

      this.removeItemWhenNotInList(this.subdomainLocales.loading, 'selectedSubdomainLocales', data, 'selectedSubdomainLocalesChanged')

      return data
    },

    languageFromSubdomainLocalesFiltered() {
      
      let data =  this.subdomainLocales.baseLanguages.map(s => s.subdomain_locale.language)

      //make a unique list of languages
      let a = []
      for(const key in data) {a['_'+data[key]['id']] = data[key]}
      let aa = []
      for (const key in a) {aa.push(a[key])}
      data = aa

      this.removeItemWhenNotInList(this.subdomainLocales.loading, 'selectedLanguagesFromSubdomainLocales', data, 'selectedSubdomainLocalesChanged')

      return data
    },

    adunitsFiltered() {
      return this.adunits.all.map(a => a.adunit)
    },
  },

  methods: {
    setGroup(all, type, isSingle = false) {
      if(isSingle) return all;

      if(this.trackSelectLabel[type] === undefined) this.trackSelectLabel[type] = true
      return [{label: this.trackSelectLabel[type] ? 'Select all' : 'Unselect all', all}]
    },

    unselectGroup(selectedItems, objName, isSingle = false) {
      if(isSingle) return;

      //avoid deleting all selection when they deselect only one item
      if(Object.keys(selectedItems)?.length === this[objName]?.length) {
        this.$nextTick(() => {
          Vue.set(this, objName, [])
        })
      }
    },

    selectedAgencyFeedsChanged(){
      this.trackSelectLabel['agencyFeeds'] = this.agencyFeeds.all.length !== this.selectedAgencyFeeds.length

      if (!Array.isArray(this.selectedNetworks)) this.selectedNetworks = this.selectedNetworks ? [this.selectedNetworks] : []
      if (!Array.isArray(this.selectedAvailableNetworks)) this.selectedAvailableNetworks = this.selectedAvailableNetworks ? [this.selectedAvailableNetworks] : []
      if (!Array.isArray(this.selectedPartners)) this.selectedPartners = this.selectedPartners ? [this.selectedPartners] : []
      if (!Array.isArray(this.selectedSubdomainLocales)) this.selectedSubdomainLocales = this.selectedSubdomainLocales ? [this.selectedSubdomainLocales] : []
      if(this.productsWithAvailableUrlCodesEnabled)
        if (!Array.isArray(this.selectedProductsWithAvailableUrlCodes)) this.selectedProductsWithAvailableUrlCodes = this.selectedProductsWithAvailableUrlCodes ? [this.selectedProductsWithAvailableUrlCodes] : []
      if(this.productsEnabled)
        if (!Array.isArray(this.selectedProducts)) this.selectedProducts = this.selectedProducts ? [this.selectedProducts] : []
      
      let networks = this.availableNetworksEnabled ? this.selectedAvailableNetworks : this.selectedNetworks
      let partners = this.partnersAvailableEnabled ? this.selectedAvailablePartners : this.selectedPartners
      let products = this.productsWithAvailableUrlCodesEnabled ? this.selectedProductsWithAvailableUrlCodes : this.selectedProducts
      let countries = this.customizeForPlusModal ?
                    this.selectedSubdomainLocales.map((element) => { return element.country_id }) :
                    this.selectedCountries.map((element) => { return element.id })
      let languages = this.languageFromSubdomainLocalesEnabled ? this.selectedLanguagesFromSubdomainLocales : this.selectedLanguages

      if(this.networksEnabled)
        this.$store.dispatch('networks/getAll', {
          'agency_feed__in': this.selectedAgencyFeeds.map((element) => { return element.id }).join(),
          'partner__in': partners.map((element) => { return element.id }).join(),
          'country__in': countries.join(),
          'product__in': products.map((element) => { return element.id }).join(),
          'language__in': languages.map((element) => { return element.id }).join(),
          'adunit__in': this.selectedAdunits.map((element) => { return element.id }).join(),
        });

      if(this.availableNetworksEnabled)
        this.$store.dispatch('networks/getAllAvailableNetworks', {
          'agency_feed__in': this.selectedAgencyFeeds.map((element) => { return element.id }).join(),
          'partner__in': partners.map((element) => { return element.id }).join(),
          'country__in': countries.join(),
          'product__in': products.map((element) => { return element.id }).join(),
          'language__in': languages.map((element) => { return element.id }).join(),
          'adunit__in': this.selectedAdunits.map((element) => { return element.id }).join(),
        });

      if(this.productsEnabled)
        this.$store.dispatch('products/getAll', {
          'agency_feed__in': this.selectedAgencyFeeds.map((element) => { return element.id }),
          'network__in': networks?.map((element) => { return element.id }),
          'partner__in': partners.map((element) => { return element.id }),
          'country__in': countries,
          'adunit__in': this.selectedAdunits.map((element) => { return element.id }),
          'language__in': languages.map((element) => { return element.id }),
        });

      if(this.productsWithAvailableUrlCodesEnabled)
        this.$store.dispatch('products/getAllProductsWithAvailableUrlCodes', {
          'agency_feed__in': this.selectedAgencyFeeds.map((element) => { return element.id }),
          'network__in': networks?.map((element) => { return element.id }),
          'partner__in': partners.map((element) => { return element.id }),
          'country__in': countries,
          'language__in': languages.map((element) => { return element.id }),
          'adunit__in': this.selectedAdunits.map((element) => { return element.id }),
        });

      if(this.partnersEnabled)
        this.$store.dispatch('partners/getAll', {
          'agency_feed__in': this.selectedAgencyFeeds.map((element) => { return element.id }).join(),
          'network__in': networks?.map((element) => { return element.id }).join(),
          'country__in': countries.join(),
          'product__in': products.map((element) => { return element.id }).join(),
          'language__in': languages.map((element) => { return element.id }).join(),
          'adunit__in': this.selectedAdunits.map((element) => { return element.id }).join(),
        });

      if(this.partnersAvailableEnabled)
        this.$store.dispatch('partners/getAllAvailablePartners', {
          'agency_feed__in': this.selectedAgencyFeeds.map((element) => { return element.id }).join(),
          'network__in': networks?.map((element) => { return element.id }).join(),
          'country__in': countries.join(),
          'product__in': products.map((element) => { return element.id }).join(),
          'language__in': languages.map((element) => { return element.id }).join(),
          'adunit__in': this.selectedAdunits.map((element) => { return element.id }).join(),
        });

      if(this.subdomainLocalesEnabled)
        this.$store.dispatch('subdomainLocales/getAll', {
          'agency_feed__in': this.selectedAgencyFeeds.map((element) => { return element.id }).join(),
          'network__in': networks?.map((element) => { return element.id }).join(),
          'language__in': languages.map((element) => { return element.id }).join(),
          // 'country__in': countries.join(),
          'product__in': products.map((element) => { return element.id }).join(),
          'partner__in': partners.map((element) => { return element.id }).join(),
          'adunit__in': this.selectedAdunits.map((element) => { return element.id }).join(),
        });

      if(this.languageFromSubdomainLocalesEnabled)
        this.$store.dispatch('subdomainLocales/getBaseLanguages', {
          'agency_feed__in': this.selectedAgencyFeeds.map((element) => { return element.id }).join(),
          'network__in': networks?.map((element) => { return element.id }).join(),
          'product__in': products.map((element) => { return element.id }).join(),
          'partner__in': partners.map((element) => { return element.id }).join(),
          // 'language__in': languages.map((element) => { return element.id }).join(),
          'country__in': countries.join(),
          'adunit__in': this.selectedAdunits.map((element) => { return element.id }).join(),
        });

      if(this.adunitsEnabled)
        this.$store.dispatch('adunits/getAll', {
          'agency_feed__in': this.selectedAgencyFeeds.map((element) => { return element.id }).join(),
          'network__in': networks?.map((element) => { return element.id }).join(),
          'country__in': countries.join(),
          'product__in': products.map((element) => { return element.id }).join(),
          'partner__in': partners.map((element) => { return element.id }).join(),
          'language__in': languages.map((element) => { return element.id }).join(),
        });
    },

    selectedNetworksChanged(){
      
      if (!Array.isArray(this.selectedNetworks)) this.selectedNetworks = this.selectedNetworks ? [this.selectedNetworks] : []
      if (!Array.isArray(this.selectedAvailableNetworks)) this.selectedAvailableNetworks = this.selectedAvailableNetworks ? [this.selectedAvailableNetworks] : []
  
      let networks = this.availableNetworksEnabled ? this.selectedAvailableNetworks : this.selectedNetworks
      this.trackSelectLabel['networks'] = this.networksFiltered?.length !== networks?.length

      if (!Array.isArray(this.selectedPartners)) this.selectedPartners = this.selectedPartners ? [this.selectedPartners] : []
      if (!Array.isArray(this.selectedSubdomainLocales)) this.selectedSubdomainLocales = this.selectedSubdomainLocales ? [this.selectedSubdomainLocales] : []
      if(this.productsWithAvailableUrlCodesEnabled)
        if (!Array.isArray(this.selectedProductsWithAvailableUrlCodes)) this.selectedProductsWithAvailableUrlCodes = this.selectedProductsWithAvailableUrlCodes ? [this.selectedProductsWithAvailableUrlCodes] : []
      if(this.productsEnabled)
        if (!Array.isArray(this.selectedProducts)) this.selectedProducts = this.selectedProducts ? [this.selectedProducts] : []
      
      let partners = this.partnersAvailableEnabled ? this.selectedAvailablePartners : this.selectedPartners
      let products = this.productsWithAvailableUrlCodesEnabled ? this.selectedProductsWithAvailableUrlCodes : this.selectedProducts
      let countries = this.customizeForPlusModal ?
                    this.selectedSubdomainLocales.map((element) => { return element.country_id }) :
                    this.selectedCountries.map((element) => { return element.id })
      let languages = this.languageFromSubdomainLocalesEnabled ? this.selectedLanguagesFromSubdomainLocales : this.selectedLanguages

      if(this.networksEnabled)
        this.$store.dispatch('networks/getAll', {
          'agency_feed__in': this.selectedAgencyFeeds.map((element) => { return element.id }).join(),
          'partner__in': partners.map((element) => { return element.id }).join(),
          'country__in': countries.join(),
          'product__in': products.map((element) => { return element.id }).join(),
          'language__in': languages.map((element) => { return element.id }).join(),
          'adunit__in': this.selectedAdunits.map((element) => { return element.id }).join(),
        });

      if(this.availableNetworksEnabled)
        this.$store.dispatch('networks/getAllAvailableNetworks', {
          'agency_feed__in': this.selectedAgencyFeeds.map((element) => { return element.id }).join(),
          'partner__in': partners.map((element) => { return element.id }).join(),
          'country__in': countries.join(),
          'product__in': products.map((element) => { return element.id }).join(),
          'language__in': languages.map((element) => { return element.id }).join(),
          'adunit__in': this.selectedAdunits.map((element) => { return element.id }).join(),
        });
      
      if(this.productsWithAvailableUrlCodesEnabled)
        this.$store.dispatch('products/getAllProductsWithAvailableUrlCodes', {
          'agency_feed__in': this.selectedAgencyFeeds.map((element) => { return element.id }),
          'network__in': networks?.map((element) => { return element.id }),
          'partner__in': partners.map((element) => { return element.id }),
          'country__in': countries,
          'language__in': languages.map((element) => { return element.id }),
          'adunit__in': this.selectedAdunits.map((element) => { return element.id }),
        });

      if(this.productsEnabled)
        this.$store.dispatch('products/getAll', {
          'agency_feed__in': this.selectedAgencyFeeds.map((element) => { return element.id }),
          'network__in': networks?.map((element) => { return element.id }),
          'partner__in': partners.map((element) => { return element.id }),
          'country__in': countries,
          'language__in': languages.map((element) => { return element.id }),
          'adunit__in': this.selectedAdunits.map((element) => { return element.id }),
        });

      if(this.partnersEnabled)
        this.$store.dispatch('partners/getAll', {
          'network__in': networks?.map((element) => { return element.id }).join(),
          'country__in': countries.join(),
          'product__in': products.map((element) => { return element.id }).join(),
          'language__in': languages.map((element) => { return element.id }).join(),
          'adunit__in': this.selectedAdunits.map((element) => { return element.id }).join(),
        });

      if(this.partnersAvailableEnabled)
        this.$store.dispatch('partners/getAllAvailablePartners', {
          'network__in': networks?.map((element) => { return element.id }).join(),
          'country__in': countries.join(),
          'product__in': products.map((element) => { return element.id }).join(),
          'language__in': languages.map((element) => { return element.id }).join(),
          'adunit__in': this.selectedAdunits.map((element) => { return element.id }).join(),
        });

      if(this.subdomainLocalesEnabled) 
        this.$store.dispatch('subdomainLocales/getAll', {
          'agency_feed__in': this.selectedAgencyFeeds.map((element) => { return element.id }).join(),
          'network__in': networks?.map((element) => { return element.id }).join(),
          'language__in': languages.map((element) => { return element.id }).join(),
          // 'country__in': countries.join(),
          'product__in': products.map((element) => { return element.id }).join(),
          'partner__in': partners.map((element) => { return element.id }).join(),
          'adunit__in': this.selectedAdunits.map((element) => { return element.id }).join(),
        });

      if(this.languageFromSubdomainLocalesEnabled)
        this.$store.dispatch('subdomainLocales/getBaseLanguages', {
          'agency_feed__in': this.selectedAgencyFeeds.map((element) => { return element.id }).join(),
          'network__in': networks?.map((element) => { return element.id }).join(),
          'product__in': products.map((element) => { return element.id }).join(),
          'partner__in': partners.map((element) => { return element.id }).join(),
          // 'language__in': languages.map((element) => { return element.id }).join(),
          'country__in': countries.join(),
          'adunit__in': this.selectedAdunits.map((element) => { return element.id }).join(),
        });

      if(this.countriesEnabled)
        this.$store.dispatch('countries/getAll', {
          'network__in': networks?.map((element) => { return element.id }).join(),
          'product__in': products.map((element) => { return element.id }).join(),
          'partner__in': partners.map((element) => { return element.id }).join(),
          'language__in': languages.map((element) => { return element.id }).join(),
          'adunit__in': this.selectedAdunits.map((element) => { return element.id }).join(),
        });

      if(this.agencyFeedsEnabled)
        this.$store.dispatch('agencyFeeds/getAll', {
          'network__in': networks?.map((element) => { return element.id }).join(),
          'country__in': countries.join(),
          'product__in': products.map((element) => { return element.id }).join(),
          'partner__in': partners.map((element) => { return element.id }).join(),
          'language__in': languages.map((element) => { return element.id }).join(),
          'adunit__in': this.selectedAdunits.map((element) => { return element.id }).join(),
        });

      if(this.adunitsEnabled)
        this.$store.dispatch('adunits/getAll', {
          'agency_feed__in': this.selectedAgencyFeeds.map((element) => { return element.id }).join(),
          'network__in': networks?.map((element) => { return element.id }).join(),
          'country__in': countries.join(),
          'product__in': products.map((element) => { return element.id }).join(),
          'partner__in': partners.map((element) => { return element.id }).join(),
          'language__in': languages.map((element) => { return element.id }).join(),
        });
    },

    selectedLanguagesChanged() {
      this.trackSelectLabel['languages'] = this.languages.all.length !== this.selectedLanguages.length
      let networks = this.availableNetworksEnabled ? this.selectedAvailableNetworks : this.selectedNetworks
      if (!Array.isArray(this.selectedNetworks)) this.selectedNetworks = this.selectedNetworks ? [this.selectedNetworks] : []
      if (!Array.isArray(this.selectedAvailableNetworks)) this.selectedAvailableNetworks = this.selectedAvailableNetworks ? [this.selectedAvailableNetworks] : []

      if(this.subdomainLocalesEnabled)
        this.$store.dispatch('subdomainLocales/getAll', {
          'agency_feed__in': this.selectedAgencyFeeds.map((element) => { return element.id }).join(),
          'network__in': networks?.map((element) => { return element.id }).join(),
          'language__in': this.selectedLanguages.map((element) => { return element.id }).join(),
          'country__in': this.selectedCountries.map((element) => { return element.id }).join(),
          'adunit__in': this.selectedAdunits.map((element) => { return element.id }).join(),
        });
    },

    selectedCountriesChanged() {
      this.trackSelectLabel['countries'] = this.countries.all.length !== this.selectedCountries.length

      if (!Array.isArray(this.selectedNetworks)) this.selectedNetworks = this.selectedNetworks ? [this.selectedNetworks] : []
      if (!Array.isArray(this.selectedAvailableNetworks)) this.selectedAvailableNetworks = this.selectedAvailableNetworks ? [this.selectedAvailableNetworks] : []
      if (!Array.isArray(this.selectedPartners)) this.selectedPartners = this.selectedPartners ? [this.selectedPartners] : []
      if (!Array.isArray(this.selectedSubdomainLocales)) this.selectedSubdomainLocales = this.selectedSubdomainLocales ? [this.selectedSubdomainLocales] : []
      if(this.productsWithAvailableUrlCodesEnabled)
        if (!Array.isArray(this.selectedProductsWithAvailableUrlCodes)) this.selectedProductsWithAvailableUrlCodes = this.selectedProductsWithAvailableUrlCodes ? [this.selectedProductsWithAvailableUrlCodes] : []
      if(this.productsEnabled)
        if (!Array.isArray(this.selectedProducts)) this.selectedProducts = this.selectedProducts ? [this.selectedProducts] : []
      
      let networks = this.availableNetworksEnabled ? this.selectedAvailableNetworks : this.selectedNetworks
      let products = this.productsWithAvailableUrlCodesEnabled ? this.selectedProductsWithAvailableUrlCodes : this.selectedProducts
      let countries = this.customizeForPlusModal ?
                    this.selectedSubdomainLocales.map((element) => { return element.country_id }) :
                    this.selectedCountries.map((element) => { return element.id })
      let partners = this.partnersAvailableEnabled ? this.selectedAvailablePartners : this.selectedPartners
      let languages = this.languageFromSubdomainLocalesEnabled ? this.selectedLanguagesFromSubdomainLocales : this.selectedLanguages
      
      if(this.networksEnabled)
        this.$store.dispatch('networks/getAll', {
          'agency_feed__in': this.selectedAgencyFeeds.map((element) => { return element.id }).join(),
          'partner__in': partners.map((element) => { return element.id }).join(),
          'country__in': countries.join(),
          'product__in': products.map((element) => { return element.id }).join(),
          'language__in': languages.map((element) => { return element.id }).join(),
          'adunit__in': this.selectedAdunits.map((element) => { return element.id }).join(),
        });

      if(this.availableNetworksEnabled)
        this.$store.dispatch('networks/getAllAvailableNetworks', {
          'agency_feed__in': this.selectedAgencyFeeds.map((element) => { return element.id }).join(),
          'partner__in': partners.map((element) => { return element.id }).join(),
          'country__in': countries.join(),
          'product__in': products.map((element) => { return element.id }).join(),
          'language__in': languages.map((element) => { return element.id }).join(),
          'adunit__in': this.selectedAdunits.map((element) => { return element.id }).join(),
        });

      if(this.productsWithAvailableUrlCodesEnabled)
        this.$store.dispatch('products/getAllProductsWithAvailableUrlCodes', {
          'agency_feed__in': this.selectedAgencyFeeds.map((element) => { return element.id }),
          'network__in': networks?.map((element) => { return element.id }),
          'partner__in': partners.map((element) => { return element.id }),
          'country__in': countries,
          'product__in': products.map((element) => { return element.id }),
          'language__in': languages.map((element) => { return element.id }),
          'adunit__in': this.selectedAdunits.map((element) => { return element.id }),
        });

      if(this.productsEnabled)
        this.$store.dispatch('products/getAll', {
          'agency_feed__in': this.selectedAgencyFeeds.map((element) => { return element.id }),
          'network__in': networks?.map((element) => { return element.id }),
          'partner__in': partners.map((element) => { return element.id }),
          'country__in': countries,
          'product__in': products.map((element) => { return element.id }),
          'language__in': languages.map((element) => { return element.id }),
          'adunit__in': this.selectedAdunits.map((element) => { return element.id }),
        });

      if(this.partnersEnabled)
        this.$store.dispatch('partners/getAll', {
          'network__in': networks?.map((element) => { return element.id }).join(),
          'country__in': countries.join(),
          'product__in': products.map((element) => { return element.id }).join(),
          'language__in': languages.map((element) => { return element.id }).join(),
          'adunit__in': this.selectedAdunits.map((element) => { return element.id }).join(),
        });

      if(this.partnersAvailableEnabled)
        this.$store.dispatch('partners/getAllAvailablePartners', {
          'network__in': networks?.map((element) => { return element.id }).join(),
          'country__in': countries.join(),
          'product__in': products.map((element) => { return element.id }).join(),
          'language__in': languages.map((element) => { return element.id }).join(),
          'adunit__in': this.selectedAdunits.map((element) => { return element.id }).join(),
        });

      if(this.subdomainLocalesEnabled)
        this.$store.dispatch('subdomainLocales/getAll', {
          'agency_feed__in': this.selectedAgencyFeeds.map((element) => { return element.id }).join(),
          'network__in': networks?.map((element) => { return element.id }).join(),
          'language__in': languages.map((element) => { return element.id }).join(),
          // 'country__in': countries.join(),
          'adunit__in': this.selectedAdunits.map((element) => { return element.id }).join(),
        });

      if(this.languageFromSubdomainLocalesEnabled)
        this.$store.dispatch('subdomainLocales/getBaseLanguages', {
          'agency_feed__in': this.selectedAgencyFeeds.map((element) => { return element.id }).join(),
          'network__in': networks?.map((element) => { return element.id }).join(),
          'product__in': products.map((element) => { return element.id }).join(),
          'partner__in': partners.map((element) => { return element.id }).join(),
          // 'language__in': languages.map((element) => { return element.id }).join(),
          'country__in': countries.join(),
          'adunit__in': this.selectedAdunits.map((element) => { return element.id }).join(),
        });

      if(this.adunitsEnabled)
        this.$store.dispatch('adunits/getAll', {
          'agency_feed__in': this.selectedAgencyFeeds.map((element) => { return element.id }).join(),
          'network__in': networks?.map((element) => { return element.id }).join(),
          'country__in': countries.join(),
          'product__in': products.map((element) => { return element.id }).join(),
          'partner__in': partners.map((element) => { return element.id }).join(),
          'language__in': languages.map((element) => { return element.id }).join(),
        });
    },

    selectedPartnersChanged() {
      this.trackSelectLabel['partners'] = this.partners.all.length !== this.selectedPartners.length

            if (!Array.isArray(this.selectedNetworks)) this.selectedNetworks = this.selectedNetworks ? [this.selectedNetworks] : []
      if (!Array.isArray(this.selectedAvailableNetworks)) this.selectedAvailableNetworks = this.selectedAvailableNetworks ? [this.selectedAvailableNetworks] : []
      if (!Array.isArray(this.selectedPartners)) this.selectedPartners = this.selectedPartners ? [this.selectedPartners] : []
      if (!Array.isArray(this.selectedSubdomainLocales)) this.selectedSubdomainLocales = this.selectedSubdomainLocales ? [this.selectedSubdomainLocales] : []
      if(this.productsWithAvailableUrlCodesEnabled)
        if (!Array.isArray(this.selectedProductsWithAvailableUrlCodes)) this.selectedProductsWithAvailableUrlCodes = this.selectedProductsWithAvailableUrlCodes ? [this.selectedProductsWithAvailableUrlCodes] : []
      if(this.productsEnabled)
        if (!Array.isArray(this.selectedProducts)) this.selectedProducts = this.selectedProducts ? [this.selectedProducts] : []
      
      let networks = this.availableNetworksEnabled ? this.selectedAvailableNetworks : this.selectedNetworks
      let partners = this.partnersAvailableEnabled ? this.selectedAvailablePartners : this.selectedPartners
      let products = this.productsWithAvailableUrlCodesEnabled ? this.selectedProductsWithAvailableUrlCodes : this.selectedProducts
      let countries = this.customizeForPlusModal ?
                    this.selectedSubdomainLocales.map((element) => { return element.country_id }) :
                    this.selectedCountries.map((element) => { return element.id })
      let languages = this.languageFromSubdomainLocalesEnabled ? this.selectedLanguagesFromSubdomainLocales : this.selectedLanguages

      if(this.networksEnabled)
        this.$store.dispatch('networks/getAll', {
          'agency_feed__in': this.selectedAgencyFeeds.map((element) => { return element.id }).join(),
          'partner__in': partners.map((element) => { return element.id }).join(),
          'country__in': countries.join(),
          'product__in': products.map((element) => { return element.id }).join(),
          'language__in': languages.map((element) => { return element.id }).join(),
          'adunit__in': this.selectedAdunits.map((element) => { return element.id }).join(),
        });

      if(this.availableNetworksEnabled)
        this.$store.dispatch('networks/getAllAvailableNetworks', {
          'agency_feed__in': this.selectedAgencyFeeds.map((element) => { return element.id }).join(),
          'partner__in': partners.map((element) => { return element.id }).join(),
          'country__in': countries.join(),
          'product__in': products.map((element) => { return element.id }).join(),
          'language__in': languages.map((element) => { return element.id }).join(),
          'adunit__in': this.selectedAdunits.map((element) => { return element.id }).join(),
        });

      if(this.productsEnabled)
        this.$store.dispatch('products/getAll', {
          'partner__in': partners.map((element) => { return element.id }),
          'network__in': networks?.map((element) => { return element.id }),
          'agency_feed__in': this.selectedAgencyFeeds.map((element) => { return element.id }),
          'country__in': countries,
          'language__in': languages.map((element) => { return element.id }),
          'adunit__in': this.selectedAdunits.map((element) => { return element.id }),
        });

      if(this.productsWithAvailableUrlCodesEnabled)
        this.$store.dispatch('products/getAllProductsWithAvailableUrlCodes', {
          'partner__in': partners.map((element) => { return element.id }),
          'network__in': networks?.map((element) => { return element.id }),
          'agency_feed__in': this.selectedAgencyFeeds.map((element) => { return element.id }),
          'country__in': countries,
          'language__in': languages.map((element) => { return element.id }),
          'adunit__in': this.selectedAdunits.map((element) => { return element.id }),
        });


      if(this.subdomainLocalesEnabled)
        this.$store.dispatch('subdomainLocales/getAll', {
          'agency_feed__in': this.selectedAgencyFeeds.map((element) => { return element.id }).join(),
          'network__in': networks?.map((element) => { return element.id }).join(),
          'language__in': languages.map((element) => { return element.id }).join(),
          // 'country__in': countries.join(),
          'product__in': products.map((element) => { return element.id }).join(),
          'partner__in': partners.map((element) => { return element.id }).join(),
          'adunit__in': this.selectedAdunits.map((element) => { return element.id }).join(),
        });

      if(this.countriesEnabled)
        this.$store.dispatch('countries/getAll', {
          'network__in': networks?.map((element) => { return element.id }).join(),
          'product__in': products.map((element) => { return element.id }).join(),
          'partner__in': partners.map((element) => { return element.id }).join(),
          'language__in': languages.map((element) => { return element.id }).join(),
          'adunit__in': this.selectedAdunits.map((element) => { return element.id }).join(),
        });

      if(this.agencyFeedsEnabled)
        this.$store.dispatch('agencyFeeds/getAll', {
          'network__in': networks?.map((element) => { return element.id }).join(),
          'country__in': countries.join(),
          'product__in': products.map((element) => { return element.id }).join(),
          'partner__in': partners.map((element) => { return element.id }).join(),
          'language__in': languages.map((element) => { return element.id }).join(),
          'adunit__in': this.selectedAdunits.map((element) => { return element.id }).join(),
        });

      if(this.languageFromSubdomainLocalesEnabled)
        this.$store.dispatch('subdomainLocales/getBaseLanguages', {
          'agency_feed__in': this.selectedAgencyFeeds.map((element) => { return element.id }).join(),
          'network__in': networks?.map((element) => { return element.id }).join(),
          'product__in': products.map((element) => { return element.id }).join(),
          'partner__in': partners.map((element) => { return element.id }).join(),
          // 'language__in': languages.map((element) => { return element.id }).join(),
          'country__in': countries.join(),
          'adunit__in': this.selectedAdunits.map((element) => { return element.id }).join(),
        });

      if(this.adunitsEnabled)
        this.$store.dispatch('adunits/getAll', {
          'agency_feed__in': this.selectedAgencyFeeds.map((element) => { return element.id }).join(),
          'network__in': networks?.map((element) => { return element.id }).join(),
          'country__in': countries.join(),
          'product__in': products.map((element) => { return element.id }).join(),
          'partner__in': partners.map((element) => { return element.id }).join(),
          'language__in': languages.map((element) => { return element.id }).join(),
        });
    },

    selectedProductsWithAvailableCodesChanged() {
      this.trackSelectLabel['productsWithAvailableCodes'] = this.productsWithAvailableUrlCodesFiltered.length !== this.selectedProductsWithAvailableUrlCodes.length

            if (!Array.isArray(this.selectedNetworks)) this.selectedNetworks = this.selectedNetworks ? [this.selectedNetworks] : []
      if (!Array.isArray(this.selectedAvailableNetworks)) this.selectedAvailableNetworks = this.selectedAvailableNetworks ? [this.selectedAvailableNetworks] : []
      if (!Array.isArray(this.selectedPartners)) this.selectedPartners = this.selectedPartners ? [this.selectedPartners] : []
      if (!Array.isArray(this.selectedSubdomainLocales)) this.selectedSubdomainLocales = this.selectedSubdomainLocales ? [this.selectedSubdomainLocales] : []
      if(this.productsWithAvailableUrlCodesEnabled)
        if (!Array.isArray(this.selectedProductsWithAvailableUrlCodes)) this.selectedProductsWithAvailableUrlCodes = this.selectedProductsWithAvailableUrlCodes ? [this.selectedProductsWithAvailableUrlCodes] : []
      if(this.productsEnabled)
        if (!Array.isArray(this.selectedProducts)) this.selectedProducts = this.selectedProducts ? [this.selectedProducts] : []
        
      let networks = this.availableNetworksEnabled ? this.selectedAvailableNetworks : this.selectedNetworks
      let partners = this.partnersAvailableEnabled ? this.selectedAvailablePartners : this.selectedPartners
      let products = this.productsWithAvailableUrlCodesEnabled ? this.selectedProductsWithAvailableUrlCodes : this.selectedProducts
      let countries = this.customizeForPlusModal ?
                    this.selectedSubdomainLocales.map((element) => { return element.country_id }) :
                    this.selectedCountries.map((element) => { return element.id })
      let languages = this.languageFromSubdomainLocalesEnabled ? this.selectedLanguagesFromSubdomainLocales : this.selectedLanguages

      if(this.networksEnabled)
        this.$store.dispatch('networks/getAll', {
          'agency_feed__in': this.selectedAgencyFeeds.map((element) => { return element.id }).join(),
          'partner__in': partners.map((element) => { return element.id }).join(),
          'country__in': countries.join(),
          'product__in': products.map((element) => { return element.id }).join(),
          'language__in': languages.map((element) => { return element.id }).join(),
          'adunit__in': this.selectedAdunits.map((element) => { return element.id }).join(),
        });

      if(this.availableNetworksEnabled)
        this.$store.dispatch('networks/getAllAvailableNetworks', {
          'agency_feed__in': this.selectedAgencyFeeds.map((element) => { return element.id }).join(),
          'partner__in': partners.map((element) => { return element.id }).join(),
          'country__in': countries.join(),
          'product__in': products.map((element) => { return element.id }).join(),
          'language__in': languages.map((element) => { return element.id }).join(),
          'adunit__in': this.selectedAdunits.map((element) => { return element.id }).join(),
        });

      if(this.subdomainLocalesEnabled)
        this.$store.dispatch('subdomainLocales/getAll', {
          'agency_feed__in': this.selectedAgencyFeeds.map((element) => { return element.id }).join(),
          'network__in': networks?.map((element) => { return element.id }).join(),
          'language__in': languages.map((element) => { return element.id }).join(),
          // 'country__in': countries.join(),
          'product__in': products.map((element) => { return element.id }).join(),
          'partner__in': partners.map((element) => { return element.id }).join(),
          'adunit__in': this.selectedAdunits.map((element) => { return element.id }).join(),
        });

      if(this.countriesEnabled)
        this.$store.dispatch('countries/getAll', {
          'network__in': networks?.map((element) => { return element.id }).join(),
          'product__in': products.map((element) => { return element.id }).join(),
          'partner__in': partners.map((element) => { return element.id }).join(),
          'language__in': languages.map((element) => { return element.id }).join(),
          'adunit__in': this.selectedAdunits.map((element) => { return element.id }).join(),
        });

      if(this.partnersEnabled) {
        this.$store.dispatch('partners/getAll', {
          'agency_feed__in': this.selectedAgencyFeeds.map((element) => { return element.id }).join(),
          'network__in': networks?.map((element) => { return element.id }).join(),
          'language__in': languages.map((element) => { return element.id }).join(),
          'country__in': countries.join(),
          'product__in': products.map((element) => { return element.id }).join(),
          'adunit__in': this.selectedAdunits.map((element) => { return element.id }).join(),
        });
      }

      if(this.languageFromSubdomainLocalesEnabled)
        this.$store.dispatch('subdomainLocales/getBaseLanguages', {
          'agency_feed__in': this.selectedAgencyFeeds.map((element) => { return element.id }).join(),
          'network__in': networks?.map((element) => { return element.id }).join(),
          'product__in': products.map((element) => { return element.id }).join(),
          'partner__in': partners.map((element) => { return element.id }).join(),
          // 'language__in': languages.map((element) => { return element.id }).join(),
          'country__in': countries.join(),
          'adunit__in': this.selectedAdunits.map((element) => { return element.id }).join(),
        });

      if(this.partnersAvailableEnabled) {
        this.$store.dispatch('partners/getAllAvailablePartners', {
          'agency_feed__in': this.selectedAgencyFeeds.map((element) => { return element.id }).join(),
          'network__in': networks?.map((element) => { return element.id }).join(),
          'language__in': languages.map((element) => { return element.id }).join(),
          'country__in': countries.join(),
          'product__in': products.map((element) => { return element.id }).join(),
          'adunit__in': this.selectedAdunits.map((element) => { return element.id }).join(),
        });
      }

      if(this.agencyFeedsEnabled)
        this.$store.dispatch('agencyFeeds/getAll', {
          'network__in': networks?.map((element) => { return element.id }).join(),
          'country__in': countries.join(),
          'product__in': products.map((element) => { return element.id }).join(),
          'partner__in': partners.map((element) => { return element.id }).join(),
          'language__in': languages.map((element) => { return element.id }).join(),
          'adunit__in': this.selectedAdunits.map((element) => { return element.id }).join(),
        });

      if(this.adunitsEnabled)
        this.$store.dispatch('adunits/getAll', {
          'agency_feed__in': this.selectedAgencyFeeds.map((element) => { return element.id }).join(),
          'network__in': networks?.map((element) => { return element.id }).join(),
          'country__in': countries.join(),
          'product__in': products.map((element) => { return element.id }).join(),
          'partner__in': partners.map((element) => { return element.id }).join(),
          'language__in': languages.map((element) => { return element.id }).join(),
        });
  
    },

    selectedProductsChanged() {
      this.trackSelectLabel['products'] = this.products.all.length !== this.selectedProducts.length

      if (!Array.isArray(this.selectedNetworks)) this.selectedNetworks = this.selectedNetworks ? [this.selectedNetworks] : []
      if (!Array.isArray(this.selectedAvailableNetworks)) this.selectedAvailableNetworks = this.selectedAvailableNetworks ? [this.selectedAvailableNetworks] : []
      if (!Array.isArray(this.selectedPartners)) this.selectedPartners = this.selectedPartners ? [this.selectedPartners] : []
      if (!Array.isArray(this.selectedSubdomainLocales)) this.selectedSubdomainLocales = this.selectedSubdomainLocales ? [this.selectedSubdomainLocales] : []
      if(this.productsWithAvailableUrlCodesEnabled)
        if (!Array.isArray(this.selectedProductsWithAvailableUrlCodes)) this.selectedProductsWithAvailableUrlCodes = this.selectedProductsWithAvailableUrlCodes ? [this.selectedProductsWithAvailableUrlCodes] : []
      if(this.productsEnabled)
        if (!Array.isArray(this.selectedProducts)) this.selectedProducts = this.selectedProducts ? [this.selectedProducts] : []

      let networks = this.availableNetworksEnabled ? this.selectedAvailableNetworks : this.selectedNetworks
      let partners = this.partnersAvailableEnabled ? this.selectedAvailablePartners : this.selectedPartners
      let products = this.productsWithAvailableUrlCodesEnabled ? this.selectedProductsWithAvailableUrlCodes : this.selectedProducts
      let countries = this.customizeForPlusModal ?
                    this.selectedSubdomainLocales.map((element) => { return element.country_id }) :
                    this.selectedCountries.map((element) => { return element.id })
      let languages = this.languageFromSubdomainLocalesEnabled ? this.selectedLanguagesFromSubdomainLocales : this.selectedLanguages

      if(this.networksEnabled)
        this.$store.dispatch('networks/getAll', {
          'agency_feed__in': this.selectedAgencyFeeds.map((element) => { return element.id }).join(),
          'partner__in': partners.map((element) => { return element.id }).join(),
          'country__in': countries.join(),
          'product__in': products.map((element) => { return element.id }).join(),
          'language__in': languages.map((element) => { return element.id }).join(),
          'adunit__in': this.selectedAdunits.map((element) => { return element.id }).join(),
        });

      if(this.availableNetworksEnabled)
        this.$store.dispatch('networks/getAllAvailableNetworks', {
          'agency_feed__in': this.selectedAgencyFeeds.map((element) => { return element.id }).join(),
          'partner__in': partners.map((element) => { return element.id }).join(),
          'country__in': countries.join(),
          'product__in': products.map((element) => { return element.id }).join(),
          'language__in': languages.map((element) => { return element.id }).join(),
          'adunit__in': this.selectedAdunits.map((element) => { return element.id }).join(),
        });

      if(this.subdomainLocalesEnabled)
        this.$store.dispatch('subdomainLocales/getAll', {
          'agency_feed__in': this.selectedAgencyFeeds.map((element) => { return element.id }).join(),
          'network__in': networks?.map((element) => { return element.id }).join(),
          'language__in': languages.map((element) => { return element.id }).join(),
          // 'country__in': countries.join(),
          'product__in': this.selectedProducts.map((element) => { return element.id }).join(),
          'partner__in': partners.map((element) => { return element.id }).join(),
          'adunit__in': this.selectedAdunits.map((element) => { return element.id }).join(),
        });

      if(this.countriesEnabled)
        this.$store.dispatch('countries/getAll', {
          'network__in': networks?.map((element) => { return element.id }).join(),
          'product__in': products.map((element) => { return element.id }).join(),
          'partner__in': partners.map((element) => { return element.id }).join(),
          'language__in': languages.map((element) => { return element.id }).join(),
          'adunit__in': this.selectedAdunits.map((element) => { return element.id }).join(),
        });

      if(this.partnersEnabled)
        this.$store.dispatch('partners/getAll', {
          'agency_feed__in': this.selectedAgencyFeeds.map((element) => { return element.id }).join(),
          'network__in': networks?.map((element) => { return element.id }).join(),
          'language__in': languages.map((element) => { return element.id }).join(),
          'country__in': countries.join(),
          'product__in': products.map((element) => { return element.id }).join(),
          'adunit__in': this.selectedAdunits.map((element) => { return element.id }).join(),
        });

      if(this.partnersAvailableEnabled)
        this.$store.dispatch('partners/getAllAvailablePartners', {
          'agency_feed__in': this.selectedAgencyFeeds.map((element) => { return element.id }).join(),
          'network__in': networks?.map((element) => { return element.id }).join(),
          'language__in': languages.map((element) => { return element.id }).join(),
          'country__in': countries.join(),
          'product__in': products.map((element) => { return element.id }).join(),
          'adunit__in': this.selectedAdunits.map((element) => { return element.id }).join(),
        });

      if(this.agencyFeedsEnabled)
        this.$store.dispatch('agencyFeeds/getAll', {
          'network__in': networks?.map((element) => { return element.id }).join(),
          'country__in': countries.join(),
          'product__in': products.map((element) => { return element.id }).join(),
          'partner__in': partners.map((element) => { return element.id }).join(),
          'language__in': languages.map((element) => { return element.id }).join(),
          'adunit__in': this.selectedAdunits.map((element) => { return element.id }).join(),
        });

      if(this.languageFromSubdomainLocalesEnabled)
        this.$store.dispatch('subdomainLocales/getBaseLanguages', {
          'agency_feed__in': this.selectedAgencyFeeds.map((element) => { return element.id }).join(),
          'network__in': networks?.map((element) => { return element.id }).join(),
          'product__in': products.map((element) => { return element.id }).join(),
          'partner__in': partners.map((element) => { return element.id }).join(),
          // 'language__in': languages.map((element) => { return element.id }).join(),
          'country__in': countries.join(),
          'adunit__in': this.selectedAdunits.map((element) => { return element.id }).join(),
        });

      if(this.adunitsEnabled)
        this.$store.dispatch('adunits/getAll', {
          'agency_feed__in': this.selectedAgencyFeeds.map((element) => { return element.id }).join(),
          'network__in': networks?.map((element) => { return element.id }).join(),
          'country__in': countries.join(),
          'product__in': products.map((element) => { return element.id }).join(),
          'partner__in': partners.map((element) => { return element.id }).join(),
          'language__in': languages.map((element) => { return element.id }).join(),
        });
    },

    selectedSubdomainLocalesChanged() {
      this.trackSelectLabel['subdomainLocales'] = this.subdomainLocalesFiltered.length !== this.selectedSubdomainLocales.length

            if (!Array.isArray(this.selectedNetworks)) this.selectedNetworks = this.selectedNetworks ? [this.selectedNetworks] : []
      if (!Array.isArray(this.selectedAvailableNetworks)) this.selectedAvailableNetworks = this.selectedAvailableNetworks ? [this.selectedAvailableNetworks] : []
      if (!Array.isArray(this.selectedPartners)) this.selectedPartners = this.selectedPartners ? [this.selectedPartners] : []
      if (!Array.isArray(this.selectedSubdomainLocales)) this.selectedSubdomainLocales = this.selectedSubdomainLocales ? [this.selectedSubdomainLocales] : []
      if(this.productsWithAvailableUrlCodesEnabled)
        if (!Array.isArray(this.selectedProductsWithAvailableUrlCodes)) this.selectedProductsWithAvailableUrlCodes = this.selectedProductsWithAvailableUrlCodes ? [this.selectedProductsWithAvailableUrlCodes] : []
      if(this.productsEnabled)
        if (!Array.isArray(this.selectedProducts)) this.selectedProducts = this.selectedProducts ? [this.selectedProducts] : []
      
      let networks = this.availableNetworksEnabled ? this.selectedAvailableNetworks : this.selectedNetworks
      let partners = this.partnersAvailableEnabled ? this.selectedAvailablePartners : this.selectedPartners
      let products = this.productsWithAvailableUrlCodesEnabled ? this.selectedProductsWithAvailableUrlCodes : this.selectedProducts
      let countries = this.customizeForPlusModal ?
                    this.selectedSubdomainLocales.map((element) => { return element.country_id }) :
                    this.selectedCountries.map((element) => { return element.id })
      let languages = this.languageFromSubdomainLocalesEnabled ? this.selectedLanguagesFromSubdomainLocales : this.selectedLanguages

      if(this.networksEnabled)
        this.$store.dispatch('networks/getAll', {
          'agency_feed__in': this.selectedAgencyFeeds.map((element) => { return element.id }).join(),
          'partner__in': partners.map((element) => { return element.id }).join(),
          'country__in': countries.join(),
          'product__in': products.map((element) => { return element.id }).join(),
          'language__in': languages.map((element) => { return element.id }).join(),
          'adunit__in': this.selectedAdunits.map((element) => { return element.id }).join(),
        });

      if(this.availableNetworksEnabled)
        this.$store.dispatch('networks/getAllAvailableNetworks', {
          'agency_feed__in': this.selectedAgencyFeeds.map((element) => { return element.id }).join(),
          'partner__in': partners.map((element) => { return element.id }).join(),
          'country__in': countries.join(),
          'product__in': products.map((element) => { return element.id }).join(),
          'language__in': languages.map((element) => { return element.id }).join(),
          'adunit__in': this.selectedAdunits.map((element) => { return element.id }).join(),
        });

      if(this.productsEnabled) {
        this.$store.dispatch('products/getAll', {
          'network__in': networks?.map((element) => { return element.id }),
          'country__in': countries,
          'partner__in': partners.map((element) => { return element.id }),
          'adunit__in': this.selectedAdunits.map((element) => { return element.id }),
          'language__in': languages.map(element => element.id),
        });
      }

      if(this.productsWithAvailableUrlCodesEnabled) {
        this.$store.dispatch('products/getAllProductsWithAvailableUrlCodes', {
          'network__in': networks?.map((element) => { return element.id }),
          'country__in': countries,
          'partner__in': partners.map((element) => { return element.id }),
          'adunit__in': this.selectedAdunits.map((element) => { return element.id }),
          'language__in': languages.map(element => element.id),
        });
      }

      if(this.partnersEnabled) {
        this.$store.dispatch('partners/getAll', {
          'network__in': networks?.map((element) => { return element.id }).join(),
          'country__in': countries.join(),
          'product__in': products.map((element) => { return element.id }).join(),
          'adunit__in': this.selectedAdunits.map((element) => { return element.id }).join(),
          'language__in': languages.map(element => element.id).join(),
        });
      }

      if(this.partnersAvailableEnabled) {
        this.$store.dispatch('partners/getAllAvailablePartners', {
          'network__in': networks?.map((element) => { return element.id }).join(),
          'country__in': countries.join(),
          'product__in': products.map((element) => { return element.id }).join(),
          'adunit__in': this.selectedAdunits.map((element) => { return element.id }).join(),
          'language__in': languages.map(element => element.id).join(),
        });
      }

      if(this.agencyFeedsEnabled)
        this.$store.dispatch('agencyFeeds/getAll', {
          'network__in': networks?.map((element) => { return element.id }).join(),
          'country__in': countries.join(),
          'product__in': products.map((element) => { return element.id }).join(),
          'partner__in': partners.map((element) => { return element.id }).join(),
          'adunit__in': this.selectedAdunits.map((element) => { return element.id }).join(),
          'language__in': languages.map(element => element.id).join(),
        });

      if(this.languageFromSubdomainLocalesEnabled)
        this.$store.dispatch('subdomainLocales/getBaseLanguages', {
          'agency_feed__in': this.selectedAgencyFeeds.map((element) => { return element.id }).join(),
          'network__in': networks?.map((element) => { return element.id }).join(),
          'product__in': products.map((element) => { return element.id }).join(),
          'partner__in': partners.map((element) => { return element.id }).join(),
          // 'language__in': languages.map((element) => { return element.id }).join(),
          'country__in': countries.join(),
          'adunit__in': this.selectedAdunits.map((element) => { return element.id }).join(),
        });

      if(this.adunitsEnabled)
        this.$store.dispatch('adunits/getAll', {
          'agency_feed__in': this.selectedAgencyFeeds.map((element) => { return element.id }).join(),
          'network__in': networks?.map((element) => { return element.id }).join(),
          'country__in': countries.join(),
          'product__in': products.map((element) => { return element.id }).join(),
          'partner__in': partners.map((element) => { return element.id }).join(),
          'language__in': languages.map((element) => { return element.id }).join(),
        });
    },

    languagesFromSubdomainLocalesChanged() {
      this.trackSelectLabel['languagesFromSubdomainLocales'] = this.languageFromSubdomainLocalesFiltered.length !== this.selectedLanguagesFromSubdomainLocales.length

            if (!Array.isArray(this.selectedNetworks)) this.selectedNetworks = this.selectedNetworks ? [this.selectedNetworks] : []
      if (!Array.isArray(this.selectedAvailableNetworks)) this.selectedAvailableNetworks = this.selectedAvailableNetworks ? [this.selectedAvailableNetworks] : []
      if (!Array.isArray(this.selectedPartners)) this.selectedPartners = this.selectedPartners ? [this.selectedPartners] : []
      if (!Array.isArray(this.selectedSubdomainLocales)) this.selectedSubdomainLocales = this.selectedSubdomainLocales ? [this.selectedSubdomainLocales] : []
      if(this.productsWithAvailableUrlCodesEnabled)
        if (!Array.isArray(this.selectedProductsWithAvailableUrlCodes)) this.selectedProductsWithAvailableUrlCodes = this.selectedProductsWithAvailableUrlCodes ? [this.selectedProductsWithAvailableUrlCodes] : []
      if(this.productsEnabled)
        if (!Array.isArray(this.selectedProducts)) this.selectedProducts = this.selectedProducts ? [this.selectedProducts] : []

      let networks = this.availableNetworksEnabled ? this.selectedAvailableNetworks : this.selectedNetworks
      let partners = this.partnersAvailableEnabled ? this.selectedAvailablePartners : this.selectedPartners
      let products = this.productsWithAvailableUrlCodesEnabled ? this.selectedProductsWithAvailableUrlCodes : this.selectedProducts
      let countries = this.customizeForPlusModal ?
                    this.selectedSubdomainLocales.map((element) => { return element.country_id }) :
                    this.selectedCountries.map((element) => { return element.id })
      let languages = this.languageFromSubdomainLocalesEnabled ? this.selectedLanguagesFromSubdomainLocales : this.selectedLanguages

      if(this.networksEnabled)
        this.$store.dispatch('networks/getAll', {
          'agency_feed__in': this.selectedAgencyFeeds.map((element) => { return element.id }).join(),
          'partner__in': partners.map((element) => { return element.id }).join(),
          'country__in': countries.join(),
          'product__in': products.map((element) => { return element.id }).join(),
          'language__in': languages.map((element) => { return element.id }).join(),
          'adunit__in': this.selectedAdunits.map((element) => { return element.id }).join(),
        });

      if(this.availableNetworksEnabled)
        this.$store.dispatch('networks/getAllAvailableNetworks', {
          'agency_feed__in': this.selectedAgencyFeeds.map((element) => { return element.id }).join(),
          'partner__in': partners.map((element) => { return element.id }).join(),
          'country__in': countries.join(),
          'product__in': products.map((element) => { return element.id }).join(),
          'language__in': languages.map((element) => { return element.id }).join(),
          'adunit__in': this.selectedAdunits.map((element) => { return element.id }).join(),
        });

      if(this.productsEnabled) {
        this.$store.dispatch('products/getAll', {
          'network__in': networks?.map((element) => { return element.id }),
          'country__in': countries,
          'partner__in': partners.map((element) => { return element.id }),
          'adunit__in': this.selectedAdunits.map((element) => { return element.id }),
          'language__in': languages.map(element => element.id),
        });
      }

      if(this.productsWithAvailableUrlCodesEnabled) {
        this.$store.dispatch('products/getAllProductsWithAvailableUrlCodes', {
          'network__in': networks?.map((element) => { return element.id }),
          'country__in': countries,
          'partner__in': partners.map((element) => { return element.id }),
          'adunit__in': this.selectedAdunits.map((element) => { return element.id }),
          'language__in': languages.map(element => element.id),
        });
      }

      if(this.partnersEnabled) {
        this.$store.dispatch('partners/getAll', {
          'network__in': networks?.map((element) => { return element.id }).join(),
          'country__in': countries.join(),
          'product__in': products.map((element) => { return element.id }).join(),
          'adunit__in': this.selectedAdunits.map((element) => { return element.id }).join(),
          'language__in': languages.map(element => element.id).join(),
        });
      }

      if(this.partnersAvailableEnabled) {
        this.$store.dispatch('partners/getAllAvailablePartners', {
          'network__in': networks?.map((element) => { return element.id }).join(),
          'country__in': countries.join(),
          'product__in': products.map((element) => { return element.id }).join(),
          'adunit__in': this.selectedAdunits.map((element) => { return element.id }).join(),
          'language__in': languages.map(element => element.id).join(),
        });
      }

      if(this.agencyFeedsEnabled)
        this.$store.dispatch('agencyFeeds/getAll', {
          'network__in': networks?.map((element) => { return element.id }).join(),
          'country__in': countries.join(),
          'product__in': products.map((element) => { return element.id }).join(),
          'partner__in': partners.map((element) => { return element.id }).join(),
          'adunit__in': this.selectedAdunits.map((element) => { return element.id }).join(),
          'language__in': languages.map(element => element.id).join(),
        });

      if(this.subdomainLocalesEnabled)
        this.$store.dispatch('subdomainLocales/getAll', {
          'agency_feed__in': this.selectedAgencyFeeds.map((element) => { return element.id }).join(),
          'network__in': networks?.map((element) => { return element.id }).join(),
          'product__in': products.map((element) => { return element.id }).join(),
          'partner__in': partners.map((element) => { return element.id }).join(),
          // 'country__in': countries.join(),
          'language__in': languages.map((element) => { return element.id }).join(),
          'adunit__in': this.selectedAdunits.map((element) => { return element.id }).join(),
        });

      if(this.adunitsEnabled)
        this.$store.dispatch('adunits/getAll', {
          'agency_feed__in': this.selectedAgencyFeeds.map((element) => { return element.id }).join(),
          'network__in': networks?.map((element) => { return element.id }).join(),
          'country__in': countries.join(),
          'product__in': products.map((element) => { return element.id }).join(),
          'partner__in': partners.map((element) => { return element.id }).join(),
          'language__in': languages.map((element) => { return element.id }).join(),
        });
    },

    selectedAdunitsChanged() {
      this.trackSelectLabel['adunits'] = this.adunits.all.length !== this.selectedAdunits.length

      if (!Array.isArray(this.selectedNetworks)) this.selectedNetworks = this.selectedNetworks ? [this.selectedNetworks] : []
      if (!Array.isArray(this.selectedAvailableNetworks)) this.selectedAvailableNetworks = this.selectedAvailableNetworks ? [this.selectedAvailableNetworks] : []
      if (!Array.isArray(this.selectedPartners)) this.selectedPartners = this.selectedPartners ? [this.selectedPartners] : []
      if (!Array.isArray(this.selectedSubdomainLocales)) this.selectedSubdomainLocales = this.selectedSubdomainLocales ? [this.selectedSubdomainLocales] : []
      if(this.productsWithAvailableUrlCodesEnabled)
        if (!Array.isArray(this.selectedProductsWithAvailableUrlCodes)) this.selectedProductsWithAvailableUrlCodes = this.selectedProductsWithAvailableUrlCodes ? [this.selectedProductsWithAvailableUrlCodes] : []
      if(this.productsEnabled)
        if (!Array.isArray(this.selectedProducts)) this.selectedProducts = this.selectedProducts ? [this.selectedProducts] : []
      
      let networks = this.availableNetworksEnabled ? this.selectedAvailableNetworks : this.selectedNetworks
      let partners = this.partnersAvailableEnabled ? this.selectedAvailablePartners : this.selectedPartners
      let products = this.productsWithAvailableUrlCodesEnabled ? this.selectedProductsWithAvailableUrlCodes : this.selectedProducts
      let countries = this.customizeForPlusModal ?
                    this.selectedSubdomainLocales.map((element) => { return element.country_id }) :
                    this.selectedCountries.map((element) => { return element.id })
      let languages = this.languageFromSubdomainLocalesEnabled ? this.selectedLanguagesFromSubdomainLocales : this.selectedLanguages

      if(this.networksEnabled)
        this.$store.dispatch('networks/getAll', {
          'agency_feed__in': this.selectedAgencyFeeds.map((element) => { return element.id }).join(),
          'partner__in': partners.map((element) => { return element.id }).join(),
          'country__in': countries.join(),
          'product__in': products.map((element) => { return element.id }).join(),
          'language__in': languages.map((element) => { return element.id }).join(),
          'adunit__in': this.selectedAdunits.map((element) => { return element.id }).join(),
        });

      if(this.availableNetworksEnabled)
        this.$store.dispatch('networks/getAllAvailableNetworks', {
          'agency_feed__in': this.selectedAgencyFeeds.map((element) => { return element.id }).join(),
          'partner__in': partners.map((element) => { return element.id }).join(),
          'country__in': countries.join(),
          'product__in': products.map((element) => { return element.id }).join(),
          'language__in': languages.map((element) => { return element.id }).join(),
          'adunit__in': this.selectedAdunits.map((element) => { return element.id }).join(),
        });

      if(this.productsEnabled)
        this.$store.dispatch('products/getAll', {
          'partner__in': partners.map((element) => { return element.id }),
          'network__in': networks?.map((element) => { return element.id }),
          'agency_feed__in': this.selectedAgencyFeeds.map((element) => { return element.id }),
          'country__in': countries,
          'language__in': languages.map((element) => { return element.id }),
          'adunit__in': this.selectedAdunits.map((element) => { return element.id }),
        });

      if(this.productsWithAvailableUrlCodesEnabled)
        this.$store.dispatch('products/getAllProductsWithAvailableUrlCodes', {
          'partner__in': partners.map((element) => { return element.id }),
          'network__in': networks?.map((element) => { return element.id }),
          'agency_feed__in': this.selectedAgencyFeeds.map((element) => { return element.id }),
          'country__in': countries,
          'language__in': languages.map((element) => { return element.id }),
          'adunit__in': this.selectedAdunits.map((element) => { return element.id }),
        });

      if(this.partnersEnabled)
        this.$store.dispatch('partners/getAll', {
          'network__in': networks?.map((element) => { return element.id }).join(),
          'country__in': countries.join(),
          'product__in': products.map((element) => { return element.id }).join(),
          'adunit__in': this.selectedAdunits.map((element) => { return element.id }).join(),
          'language__in': languages.map(element => element.id).join(),
        });

      if(this.partnersAvailableEnabled)
        this.$store.dispatch('partners/getAllAvailablePartners', {
          'network__in': networks?.map((element) => { return element.id }).join(),
          'country__in': countries.join(),
          'product__in': products.map((element) => { return element.id }).join(),
          'adunit__in': this.selectedAdunits.map((element) => { return element.id }).join(),
          'language__in': languages.map(element => element.id).join(),
        });

      if(this.subdomainLocalesEnabled)
        this.$store.dispatch('subdomainLocales/getAll', {
          'agency_feed__in': this.selectedAgencyFeeds.map((element) => { return element.id }).join(),
          'network__in': networks?.map((element) => { return element.id }).join(),
          'language__in':languages.map((element) => { return element.id }).join(),
          // 'country__in': countries.join(),
          'product__in': products.map((element) => { return element.id }).join(),
          'partner__in': partners.map((element) => { return element.id }).join(),
          'adunit__in': this.selectedAdunits.map((element) => { return element.id }).join(),
        });

      if(this.languageFromSubdomainLocalesEnabled)
        this.$store.dispatch('subdomainLocales/getBaseLanguages', {
          'agency_feed__in': this.selectedAgencyFeeds.map((element) => { return element.id }).join(),
          'network__in': networks?.map((element) => { return element.id }).join(),
          'product__in': products.map((element) => { return element.id }).join(),
          'partner__in': partners.map((element) => { return element.id }).join(),
          // 'language__in': languages.map((element) => { return element.id }).join(),
          'country__in': countries.join(),
          'adunit__in': this.selectedAdunits.map((element) => { return element.id }).join(),
        });

      if(this.countriesEnabled)
        this.$store.dispatch('countries/getAll', {
          'network__in': networks?.map((element) => { return element.id }).join(),
          'product__in': products.map((element) => { return element.id }).join(),
          'partner__in': partners.map((element) => { return element.id }).join(),
          'language__in': languages.map((element) => { return element.id }).join(),
          'adunit__in': this.selectedAdunits.map((element) => { return element.id }).join(),
        });

      if(this.agencyFeedsEnabled)
        this.$store.dispatch('agencyFeeds/getAll', {
          'network__in': networks?.map((element) => { return element.id }).join(),
          'country__in': countries.join(),
          'product__in': products.map((element) => { return element.id }).join(),
          'partner__in': partners.map((element) => { return element.id }).join(),
          'language__in': languages.map((element) => { return element.id }).join(),
          'adunit__in': this.selectedAdunits.map((element) => { return element.id }).join(),
        });

      if(this.adunitsEnabled)
        this.$store.dispatch('adunits/getAll', {
          'agency_feed__in': this.selectedAgencyFeeds.map((element) => { return element.id }).join(),
          'network__in': networks?.map((element) => { return element.id }).join(),
          'country__in': countries.join(),
          'product__in': products.map((element) => { return element.id }).join(),
          'partner__in': partners.map((element) => { return element.id }).join(),
          'language__in': languages.map((element) => { return element.id }).join(),
        });
    },

    removeItemWhenNotInList(loading, selectedListName, optionList, changeFnName) {

      if(!loading) {
        this[selectedListName].forEach((a, index) => {

          let have_it = 'unset'

          if(optionList[0]?.id) {
            have_it = optionList.find(e => e.id === a.id)
          } else if(optionList[0]?.country_id) {
            have_it = optionList.find(e => e.country_id === a.country_id)
          } else if(optionList[0]?.language_id) {
            have_it = optionList.find(e => e.language_id === a.language_id)
          }

          if(have_it !== 'unset' && !have_it) {
            this[selectedListName].splice(index, 1)
            this[changeFnName]()
          }

        })
      }

    },

    getPartnerName(org) {
      console.log(org)
    },

    preselectMe() {
      let that = this;

      this.executeWhenPermissionsAreLoaded(()=>{
        if(that.isSuperUser()){
          return;
        }

        this.selectedMarketingUsers.push({
          'id': that.theme.userId,
          'username': that.theme.userName
        });
      })

    },

    filter(){
      this.triggerInput();
      this.$emit('filter');
    },

    triggerInput(){

      let obj = {};

      if(this.networksEnabled)
        obj['networks'] = this.selectedNetworks;

      if(this.availableNetworksEnabled)
        obj['availableNetworks'] = this.selectedAvailableNetworks;

      if(this.productsEnabled)
        obj['products'] = this.selectedProducts;
      
      if(this.productsWithAvailableUrlCodesEnabled)
        obj['productsWithAvailableUrlCodes'] = this.selectedProductsWithAvailableUrlCodes;

      if(this.partnersEnabled)
        obj['partners'] = this.selectedPartners;

      if(this.partnersAvailableEnabled)
        obj['availablePartners'] = this.selectedAvailablePartners;

      if(this.agencyFeedsEnabled)
        obj['agencyFeeds'] = this.selectedAgencyFeeds;

      if(this.countriesEnabled)
        obj['countries'] = this.selectedCountries;

        if (this.accountsEnabled && this.isSuperUser()){
            obj['accounts'] = this.selectedAccounts;
        }else{
            // Needed to prevent key error
          obj['accounts'] = [];
        }


      if(this.biddingStrategiesEnabled)
        obj['biddingStrategies'] = this.selectedBiddingStrategies;

      if(this.marketingUsersEnabled)
        obj['marketingUsers'] = this.selectedMarketingUsers;

      if(this.languagesEnabled)
        obj['languages'] = this.selectedLanguages;

      if(this.subdomainLocalesEnabled)
        obj['subdomainLocales'] = this.selectedSubdomainLocales;

      if(this.rangeDateEnabled)
        obj['rangeDate'] = this.selectedRangeDate;

      if(this.singleDateEnabled)
        obj['date'] = this.selectedDate;

      if(this.currenciesEnabled)
        obj['currencies'] = this.selectedCurrencies;

      if(this.marketingAgencyEnabled)
        obj['marketingAgency'] = this.selectedMarketingAgency;

      if(this.languageFromSubdomainLocalesEnabled)
        obj['languagesFromSubdomainLocales'] = this.selectedLanguagesFromSubdomainLocales;

      if(this.adunitsEnabled)
        obj['adunits'] = this.selectedAdunits;

      this.$emit('input', obj);
    },

    preselectDate() {
      let nowLosAngeles = moment().tz('America/Los_Angeles')

      if(nowLosAngeles.hour() < 1){
        this.selectedDate = moment().subtract(1, 'day').toDate()
      }else{
        this.selectedDate = moment().toDate()
      }
    },

    download(){
      this.$emit('download');
    },

    productPlaceholder() {
      let placeholder = 'Product'

      if(!this.singleProduct) placeholder = placeholder+'s'
      if(this.optionalProduct) placeholder = placeholder + ' (optional)'

      return placeholder
    },

    filterBiddingStrategyByNetworks(biddingStrategies){

      if(this.networksEnabled && this.selectedNetworks.length > 0){
        biddingStrategies = biddingStrategies.filter(biddingStrategy => {
          return this.selectedNetworks.map(network => network.dealer).indexOf(biddingStrategy.dealer) > -1;
        });
      }

      if(this.availableNetworksEnabled && this.selectedAvailableNetworks.length > 0){
        biddingStrategies = biddingStrategies.filter(biddingStrategy => {
          return this.selectedAvailableNetworks.map(network => network.dealer).indexOf(biddingStrategy.dealer) > -1;
        });
      }

      return biddingStrategies;
    }
  },

  watch: {
    selectedRangeDate(){
      this.triggerInput()
    },
    selectedDate(){
      this.triggerInput()
    },
    selectedNetworks(){
      this.triggerInput()
    },
    selectedAvailableNetworks() {
      this.triggerInput()
    },
    selectedAgencyFeeds(){
      this.triggerInput()
    },
    selectedProducts(){
      this.triggerInput()
    },
    selectedPartners(){
      this.triggerInput()
    },
    selectedAvailablePartners(){
      this.triggerInput()
    },
    selectedProductsWithAvailableUrlCodes() {
      this.triggerInput()
    },
    selectedCountries(){
      this.triggerInput()
    },
    selectedAccounts(){
      this.triggerInput()
    },
    selectedLanguages(){
      this.triggerInput()
    },
    selectedSubdomainLocales(){
      this.triggerInput()
    },
    selectedBiddingStrategies(){
      this.triggerInput()
    },
    selectedCurrencies(){
      this.triggerInput()
    },
    selectedMarketingUsers(){
      this.triggerInput()
    },
    selectedMarketingAgency() {
      this.triggerInput()
    },
    selectedLanguagesFromSubdomainLocales(){
      this.triggerInput()
    },
    selectedAdunits(){ 
      this.triggerInput()
    },
  }

}
</script>