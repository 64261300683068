<template>
  <div>
    <b-loading v-model="isLoading"></b-loading>
    <div class="modal-card" style="width: auto">
      <header class="modal-card-head">
        {{ keywordName }}
      </header>
      <section class="modal-card-body p-3">
        <div v-if="networkCode === 'OBN'">
          <b-button type="is-info" icon-left="text-recognition" size="is-small" @click="generateText('title', OBN.MAX_TITLE)">Suggest Titles</b-button>
          <b-button type="is-info" class="ml-2" icon-left="text-recognition" size="is-small" @click="generateText('description', OBN.MAX_DESCRIPTION)">Suggest Descriptions</b-button>
<!--          <b-button type="is-info" class="ml-2" icon-left="file-document-multiple-outline" size="is-small">Open Existing</b-button>-->
          <div class="columns mt-1">
            <div class="column" v-for="(obnCreative, rowId) in OBN.creatives" v-bind:key="`obn-creative-${rowId}`">
              <b-field label="Title:"
                    :type="{ 'is-danger': obnCreative.titleHasError }"
                    :message="{ 'This field is mandatory': obnCreative.titleHasError }">
                <b-input type="textarea" v-model="obnCreative.title" :maxlength="OBN.MAX_TITLE"></b-input>
              </b-field>
              <b-field label="Description:">
                <b-input type="textarea" v-model="obnCreative.description" :maxlength="OBN.MAX_DESCRIPTION"></b-input>
              </b-field>
              <b-button @click="addNewCreative" class="is-success is-small" icon-left="plus-box">Add new</b-button>
              <b-button @click="removeCreative(rowId)" v-if="OBN.creatives.length > 1" class="is-danger is-small is-pulled-right" icon-left="close">Remove</b-button>
            </div>
          </div>
        </div>
        <div v-else-if="networkCode === 'FBA'">
          <b-button type="is-info" icon-left="text-recognition" size="is-small" @click="generateText('message', FBA.MAX_MESSAGE)">Suggest Messages</b-button>
          <b-button type="is-info" class="ml-2" icon-left="text-recognition" size="is-small" @click="generateText('headline', FBA.MAX_HEADLINE)">Suggest Headlines</b-button>
          <b-button type="is-info" class="ml-2" icon-left="text-recognition" size="is-small" @click="generateText('description', FBA.MAX_DESCRIPTION)">Suggest Descriptions</b-button>
<!--          <b-button type="is-info" class="ml-2" icon-left="file-document-multiple-outline" size="is-small">Open Existing</b-button>-->
          <div class="columns mt-1">
            <div class="column" v-for="(fbaCreative, rowId) in FBA.creatives" v-bind:key="`fba-creative-${rowId}`">
              <b-field label="Message:"
                    :type="{ 'is-danger': fbaCreative.messageHasError }"
                    :message="{ 'This field is mandatory': fbaCreative.messageHasError }">
                <b-input type="textarea" v-model="fbaCreative.message" :maxlength="FBA.MAX_MESSAGE"></b-input>
              </b-field>
              <b-field label="Headline:">
                <b-input type="textarea" v-model="fbaCreative.headline" :maxlength="FBA.MAX_HEADLINE"></b-input>
              </b-field>
              <b-field label="Description:">
                <b-input type="textarea" v-model="fbaCreative.description" :maxlength="FBA.MAX_DESCRIPTION"></b-input>
              </b-field>
              <b-button @click="addNewCreative" class="is-success is-small" icon-left="plus-box">Add new</b-button>
              <b-button @click="removeCreative(rowId)" v-if="FBA.creatives.length > 1" class="is-danger is-small is-pulled-right" icon-left="close">Remove</b-button>
            </div>
          </div>
        </div>
        <div v-else-if="networkCode === 'GDN'">
          <b-button type="is-info" icon-left="text-recognition" size="is-small" @click="generateText('headline', GDN.MAX_HEADLINE)">Suggest Headline</b-button>
          <b-button type="is-info" class="ml-2" icon-left="text-recognition" size="is-small" @click="generateText('longHeadline', GDN.MAX_LONG_HEADLINE)">Suggest Long Headlines</b-button>
          <b-button type="is-info" class="ml-2" icon-left="text-recognition" size="is-small" @click="generateText('description', GDN.MAX_DESCRIPTION)">Suggest Descriptions</b-button>
<!--          <b-button type="is-info" class="ml-2" icon-left="file-document-multiple-outline" size="is-small">Open Existing</b-button>-->
          <div class="columns mt-1">
            <div class="column" v-for="(gdnCreative, rowId) in GDN.creatives" v-bind:key="`gdn-creative-${rowId}`">
              <b-field label="Headline:"
                    :type="{ 'is-danger': gdnCreative.headlineHasError }"
                    :message="{ 'This field is mandatory': gdnCreative.headlineHasError }">
                <b-input type="textarea" v-model="gdnCreative.headline" :maxlength="GDN.MAX_HEADLINE"></b-input>
              </b-field>
              <b-field label="Long Headline:">
                <b-input type="textarea" v-model="gdnCreative.longHeadline" :maxlength="GDN.MAX_LONG_HEADLINE"></b-input>
              </b-field>
              <b-field label="Description:">
                <b-input type="textarea" v-model="gdnCreative.description" :maxlength="GDN.MAX_DESCRIPTION"></b-input>
              </b-field>
              <b-button @click="addNewCreative" class="is-success is-small" icon-left="plus-box">Add new</b-button>
              <b-button @click="removeCreative(rowId)" v-if="GDN.creatives.length > 1" class="is-danger is-small is-pulled-right" icon-left="close">Remove</b-button>
            </div>
          </div>
        </div>
        <div v-else>
          {{ networkCode }} is not implemented. Notify IT team.
        </div>
      </section>
      <footer class="modal-card-foot">
          <b-button class="is-pulled-right" @click="clear">clear</b-button>
          <b-button class="is-pulled-right" @click="apply(false)">Apply</b-button>
      </footer>
    </div>
  </div>
</template>

<script>


import vue from "vue";
import {hemingwayStemmedKeywordCreatives} from "@/services/hemingway-stemmed-keyword-creative.service";
import {permissionsUtils} from "@/helpers/permissions-utils";

export default {
  name: 'CreativeSelector',
  mixins: [
      permissionsUtils
  ],
  props: {
    keywordName: {
      type: String,
    },
    initialData: {
      type: Array,
    },
    networkCode: {
      type: String,
      default: 'FBA',
      validator: (value) => {
        return [
          'OBN',
          'FBA',
          'GDN'
        ].indexOf(value) >= 0
      }
    },

  },

  mounted() {
    if(this.initialData.length > 0){
      this[this.networkCode].creatives = this.initialData;
    }else{
      this[this.networkCode].creatives.push(
          this[this.networkCode].emptyCreative
      )
    }
  },
  data() {
    return {

      isLoading: false,

      OBN: {
        MAX_TITLE: 60,
        MAX_DESCRIPTION: 150,
        emptyCreative: {

          title: '',
          titleHasError: false,

          description: '',
          descriptionHasError: false,

        },
        creatives: []
      },

      FBA: {
        MAX_MESSAGE: 125,
        MAX_HEADLINE: 60,
        MAX_DESCRIPTION: 120,
        emptyCreative: {

          message: '',
          messageHasError: false,

          headline: '',
          headlineHasError: false,

          description: '',
          descriptionHasError: false,

        },
        creatives: []
      },

      GDN: {
        MAX_HEADLINE: 30,
        MAX_LONG_HEADLINE: 90,
        MAX_DESCRIPTION: 90,
        emptyCreative: {

          headline: '',
          headlineHasError: false,

          longHeadline: '',
          longHeadlineHasError: false,

          description: '',
          descriptionHasError: false,

        },
        creatives: []
      }
    }
  },
  computed: {},
  watch: {},
  methods: {
    addNewCreative: function () {
      this[this.networkCode].creatives.push(
          {...this[this.networkCode].emptyCreative}
      )
    },

    removeCreative: function (rowId){
      if(this[this.networkCode].creatives.length === 1){
        return;
      }

      vue.delete(this[this.networkCode].creatives, rowId);
    },

    generateText(field, maxLength){
      this.isLoading = true;
      hemingwayStemmedKeywordCreatives.generate(
          this.keywordName,
          maxLength,
          this[this.networkCode].creatives.length
      ).then(response => {

        for(let i in response.data){
          let row = response.data[i]
          this[this.networkCode].creatives[i][field] = row['text'];
        }
        this.isLoading = false;
      })
    },

    apply(ignoreValidation){
      ignoreValidation = ignoreValidation || false;
      if(!ignoreValidation && !this.validate()){
        return;
      }
      this.$emit('input', this[this.networkCode].creatives)
      this.$emit('close', []);
    },
    clear(){
      this[this.networkCode].creatives = [];
      this.apply(true);
    },
    validate(){

      for(let i in this[this.networkCode].creatives){
        let creative = this[this.networkCode].creatives[i];

        if(this.networkCode === 'OBN'){
          if(creative.title === '' || !creative.title){
            creative.titleHasError = true;
            this.$buefy.snackbar.open({'message':'Title is mandatory for Outbrain.', 'position': 'is-top'});
            return false;
          }
        }else if(this.networkCode === 'FBA'){
          if(creative.message === '' || !creative.message){
            creative.messageHasError = true;
            this.$buefy.snackbar.open({'message':'Message is mandatory for Facebook.', 'position': 'is-top'});
            return false;
          }
        }else if(this.networkCode === 'GDN'){
          if(creative.headline === '' || !creative.headline){
            creative.headlineHasError = true;
            this.$buefy.snackbar.open({'message':'Headline is mandatory for Google Display.', 'position': 'is-top'});
            return false;
          }
        }

        if(['GDN', 'FBA'].indexOf('{KEYWORD}') === -1){
          let hasKeyword = false;
          for(let field in creative){
            if(creative[field] instanceof String && (creative[field].indexOf('{KEYWORD}') > -1 && creative[field].indexOf(this.keywordName) > -1 )){
              hasKeyword = true;
            }
          }

          if(!hasKeyword){
            if(this.isSuperUser()){
              let x = confirm('Attention: creative does not have keyword, use {KEYWORD} or proceed without it.')
              if(!x){
                return false;
              }
            }else{
              this.$buefy.snackbar.open({'message':'ERROR: creative does not have keyword, use {KEYWORD} or type: ' + this.keywordName + ' to proceed in at least one of the fields.', 'position': 'is-top'});
              return false;
            }
          }
        }

      }

      return true;
    }
  },
}
</script>
