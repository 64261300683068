<style scoped>
img.screenshot-preview {
  min-width: 500px !important;
  height: auto !important;
}
</style>

<template>
    <section class="section is-main-section">
        <dependant-multi-filter
            v-model="selectedMultiFilter"
            networks-enabled
            countries-enabled
            products-enabled
            partners-enabled
            marketing-users-enabled
            @filter="filterList">

            <multiselect
                v-model="status"
                :options="statusList"
                :multiple="true"
                :close-on-select="true"
                placeholder="Status"
                label="name"
                track-by="name" />

            <b-datepicker
                style="min-width: 16rem;"
                class="form-control"
                placeholder="Last Checked"
                v-model="scraping_ended_range"
                range>
                <b-button type="is-success" @click="scraping_ended_range = []">Clear</b-button>
            </b-datepicker>

        </dependant-multi-filter>

        <div>
            <b-field grouped class="is-inline-block" >
                <b-taginput
                    class="mx-0"
                    style="min-width: 200px;"
                    v-model="keywords"
                    ellipsis
                    placeholder="keywords"
                    aria-close-label="Delete this keyword">
                </b-taginput>

                <b-taginput
                    style="min-width: 200px;"
                    v-model="advertisers"
                    ellipsis
                    placeholder="Advertisers"
                    aria-close-label="Delete this advertiser">
                </b-taginput>
            </b-field>
        </div>
        <div class="is-flex is-justify-content-space-between">
            <div>
                <b-button @click="newCheckAll()" :disabled="!checkedRows.length" type="is-info" size="is-small" class="my-1">New check All</b-button>
                <b-button @click="launchAll()" :disabled="!checkedRows.length" type="is-warning" size="is-small" class="my-1 mx-1">Launch All</b-button>
                <b-button @click="expandAll()" :disabled="!advertisersCheck.all.length" type="is-success" size="is-small" class="my-1"> {{  openedDetailed.length === advertisersCheck.all.length ? 'Collapse All' : 'Expand All'  }}</b-button>
                <b-button @click=" showPlusModal = true; keywordsImported = ''" type="is-success" size="is-small" class="my-1 ml-1">+ Add Keywords to check</b-button>
            </div>
            <!-- <b-button @click="showQuickCheckModal = true; quickCheckKeyword = ''; progressBarModal.data = []" type="is-success" size="is-small" class="my-1 ml-1">quick check</b-button> -->
        </div>
            <span class="is-block mb-1">Selected: {{  checkedRows.length }}</span>

        <b-table
            :data="advertisersCheck.all"
            :striped="true"
            :hoverable="true"
            :loading="advertisersCheck.loading"
            :height="tableHeight()"
            :sticky-header="true"
            pagination-position="top"
            default-sort-direction="DESC"
            :pagination-rounded="false"
            sort-icon="chevron-up"
            sort-icon-size="is-medium"
            default-sort="_profit"
            aria-next-label="Next page"
            aria-previous-label="Previous page"
            aria-page-label="Page"
            aria-current-label="Current page"

            :per-page="advertisersCheck.paging.page_size"
            :total="advertisersCheck.paging.count"
            :current-page="advertisersCheck.paging.page_number"

            @page-change="changePage"
            backend-pagination
            paginated

            detailed
            :show-detail-icon="true"
            detail-key="id"
            @details-open="(row) => onDetailsOpened(row)"
            :opened-detailed="openedDetailed"

            checkable
            :checked-rows.sync="checkedRows"
            :scrollable="true"
            @click="makeClickableRow($event)"
            custom-row-key="id"
            narrowed>

            <div v-if="showEmptyResultMessage" slot="empty" class="notification is-warning is-light has-text-centered">
                The selected query didn't return any records, Try a different date or reduce the amount of filters.
            </div>

            <b-table-column label="Keyword" field="keyword_name" sortable v-slot="props">
                {{ props.row.keyword_name }}
            </b-table-column>

            <b-table-column label="Country" field="country" sortable v-slot="props">
                {{ getCountry(props.row.country) }}
            </b-table-column>

            <b-table-column label="Network" field="network" sortable v-slot="props">
                {{ getNetwork(props.row.network) }}
            </b-table-column>

            <b-table-column label="Partner" field="partner" sortable v-slot="props">
                {{ getPartner(props.row.partner) }}
            </b-table-column>

            <b-table-column label="Status" sortable v-slot="props">
                <b-tag :type="getLevelOneStatus(props.row).type">
                    {{ getLevelOneStatus(props.row).name }}
                </b-tag>
            </b-table-column>

            <b-table-column label="Checked on" field="last_checked_on_datetime" sortable v-slot="props">
                {{ props.row.last_checked_on_datetime ? formatDate(props.row.last_checked_on_datetime, 'YYYY-MM-DD') : '-' }}
            </b-table-column>

            <b-table-column label="Created by" field="created_by_user" sortable v-slot="props">
                {{ getUser(props.row.created_by_user) }}
            </b-table-column>

            <b-table-column label="Is launched" field="is_launched" sortable v-slot="props">
                <b-icon v-if="props.row.is_launched" icon="check-circle" type="is-success" size="is-small" />
                <b-icon v-else icon="close-circle" type="is-danger" size="is-small" />
            </b-table-column>

            <b-table-column label="Is live" field="is_live" sortable v-slot="props">

                <b-icon v-if="!props.row.is_live" icon="close-circle" size="is-small" type="is-danger" />

                <b-button
                    v-if="props.row.is_live"
                    type="is-success"
                    icon-left="check-circle"
                    @click="openOptimizationModal(props.row.keyword_name)"
                    outlined>
                    view
                </b-button>

            </b-table-column>

            <b-table-column label="Actions" sortable v-slot="props">
                <b-button @click="newCheck(props.row, $event)" :loading="getLoading(props.row, 'loadingNewCheck')" :key="'loadingNewCheck'+cc" :class="[getLoading(props.row, 'showMarkNewCheck') ? 'is-success' : 'is-info', 'is-light']" size="is-small" class="mr-1">
                    New check {{ getLoading(props.row, 'showMarkNewCheck') ? '✅' : ''}}
                </b-button>
            </b-table-column>

            <template slot="top-left">
                <b-field>
                  <b-field label="Rows" label-position="on-border" style="width: 8rem;">
                    <b-input v-model="advertisersCheck.paging.count" size="is-small" disabled />
                  </b-field>
                  <b-field label="Page Size" label-position="on-border">
                    <b-select v-model="pageSize" size="is-small">
                      <option>25</option>
                      <option>50</option>
                      <option>100</option>
                      <option>500</option>
                      <option>5000</option>
                      <option>10000</option>
                    </b-select>
                    </b-field>
                </b-field>
            </template>

            <template #detail="props">
                <article class="media advertiser-check-details">
                    <div class="media-content">
                        <div class="content">
                            <b-table
                                :striped="true"
                                :hoverable="true"
                                :sticky-header="true"
                                :data="props.row.scraping_histories"

                                pagination-position="top"
                                default-sort-direction="DESC"
                                :pagination-rounded="false"
                                sort-icon="chevron-up"
                                sort-icon-size="is-medium"
                                default-sort="_profit"
                                aria-next-label="Next page"
                                aria-previous-label="Previous page"
                                aria-page-label="Page"
                                aria-current-label="Current page"
                                :scrollable="true"
                                :selected.sync="autoSelectedRow"
                                :row-class="row => setSelectedClass(row)"
                                focusable
                                narrowed>

                                <div slot="empty" class="notification is-warning is-light has-text-centered">
                                    There is no data yet.
                                </div>
                                    <b-table-column label="Partner" field="partner" sortable v-slot="props">
                                        <!-- {{ props.row  }} -->
                                    {{ getPartner(props.row.partner) }}
                                </b-table-column>

                                <b-table-column label="Product" field="product" sortable v-slot="props">
                                    {{ getProduct(props.row.product) }}
                                </b-table-column>

                                <b-table-column label="Status" field="status" sortable v-slot="props">
                                    <b-tag :type="getStatus(props.row).type">
                                        {{ getStatus(props.row).name }}
                                    </b-tag>
                                </b-table-column>

                                <b-table-column label="Ended On" field="scraping_ended_on_datetime" sortable v-slot="props">
                                    {{ props.row.scraping_ended_on_datetime ? formatDate(props.row.scraping_ended_on_datetime, 'YYYY-MM-DD HH:mm') : '-' }}
                                </b-table-column>

                                <b-table-column label="Ads count" field="displayed_advertisers_count" sortable v-slot="props">
                                    {{ props.row.displayed_advertisers_count }}
                                </b-table-column>

                                <b-table-column label="Advertisers" field="displayed_advertisers" sortable v-slot="props">
                                    <template v-if="!props.row.displayed_advertisers.length">-</template>
                                    <span v-for="(ad, index) in props.row.displayed_advertisers" :key="index">
                                        <template v-if="ad.screenshot">
                                            <b-tooltip append-to-body multilined size="is-huge">
                                                <template v-slot:content >
                                                    <img class="screenshot-preview" :src="ad.screenshot" />
                                                </template>
                                                ({{ad.position}}) {{ ad.advertiser.name }}<template v-if="props.row.displayed_advertisers.length !== index+1">, </template>
                                            </b-tooltip>
                                        </template>
                                        <template v-else>
                                            ({{ad.position}}) {{ ad.advertiser.name }}<template v-if="props.row.displayed_advertisers.length !== index+1">, </template>
                                        </template>
                                    </span>
                                </b-table-column>

                                <b-table-column label="Actions" sortable v-slot="props">
                                    <template v-if="showLaunchBtn(props.row)">
                                        <template >
                                            <b-button @click="launch(props.row, $event)" :loading="getLoading(props.row, 'subRowLoadingLaunch')" :key="'subRowLoadingLaunch'+cc" :disabled="getLoading(props.row, 'subRowDisableLaunch')" :class="[getLoading(props.row, 'subRowShowMarkLaunch') ? 'is-success' : 'is-warning', 'is-light']" size="is-small">
                                                Launch {{ getLoading(props.row, 'subRowShowMarkLaunch') ? '✅' : ''}}
                                            </b-button>
                                        </template>
                                    </template>
                                    <span v-else>-</span>
                                </b-table-column>
                            </b-table>
                        </div>
                    </div>
                </article>
            </template>
        </b-table>

        <CustomModalWrapper @close="closePlusModal" title="" :show="showPlusModal" :can-cancel="true" classes="ads_plus" custom-style="width: 70vw !important;">
            <section class="modal-card-body p-2">
                <dependant-multi-filter
                    v-model="plusModalFilter"
                    partners-available-enabled
                    products-with-available-url-codes-enabled
                    subdomain-locales-enabled
                    customize-for-plus-modal
                    networks-enabled
                    single-network
                    filter-disabled />
                <b-input type="textarea" @keydown.tab.native.prevent="captureTab" name="textarea" v-model="keywordsImported"  placeholder="Keywords (separated by enter, tab, ( , ) or ( ; ) )"></b-input>
            </section>
            <footer class="modal-card-foot p-2">
                <b-button
                    label="Close"
                    @click="showPlusModal = false" />
                <b-button
                    label="Check"
                    type="is-primary"
                    @click="plusModalCheck"/>
            </footer>
        </CustomModalWrapper>

        <CustomModalWrapper title="" :show="showQuickCheckModal" classes="ads_plus" custom-style="width: 70vw !important;">
            <section class="modal-card-body p-2">
                <dependant-multi-filter
                    v-model="plusModalFilter"
                    partners-enabled
                    products-with-available-url-codes-enabled
                    subdomain-locales-enabled
                    customize-for-plus-modal
                    networks-enabled
                    single-network
                    single-partner
                    single-product
                    single-subdomain-locales
                    filter-disabled />
                <b-input type="textarea" @keydown.tab.native.prevent="captureTab" name="textarea" v-model="quickCheckKeyword"  placeholder="Type a Keyword" />
                <div v-if="progressBarModal.data.length">
                    <section class="modal-card-body p-0 pt-2">
                        <b-table class="table" :data="progressBarModal.data" hoverable bordered>
                            <b-table-column  label="Keyword">
                                {{ progressBarModal.keyword_name }}
                            </b-table-column>
                            <b-table-column  label="Total" v-slot="props">
                                {{ props.row.success.length + props.row.errors.length }}
                            </b-table-column>
                            <b-table-column  label="Ads count" v-slot="props">
                                {{ props.row.success.displayed_advertisers_count || 0 }}
                            </b-table-column>

                            <b-table-column label="Advertisers" v-slot="props">
                                <div v-for="(row, i) in props.row.success" :key="i">
                                    <template v-if="!row.displayed_advertisers.length">-</template>
                                    <template v-else>
                                        <span v-for="(ad, index) in row.displayed_advertisers" :key="ad.id+''+index">
                                            <template v-if="ad.screenshot">
                                                <b-tooltip :append-to-body="false">
                                                    <template v-slot:content >
                                                        <img class="screenshot-preview" :src="ad.screenshot" />
                                                    </template>
                                                    ({{ad.position}}) {{ ad.name }}<template v-if="row.displayed_advertisers.length !== index+1">, </template>
                                                </b-tooltip>
                                            </template>
                                            <template v-else>
                                                ({{ad.position}}) {{ ad.name }}<template v-if="row.displayed_advertisers.length !== index+1">, </template>
                                            </template>
                                        </span>
                                    </template>
                                    <template v-if="props.row.success.length !== i+1">
                                        <hr class="my-1" />
                                    </template>
                                </div>
                            </b-table-column>

                            <b-table-column  label="Success" v-slot="props">
                                {{ props.row.success.length }}
                            </b-table-column>
                            <b-table-column  label="Errors" v-slot="props" width="180">
                                {{ props.row.errors.length }} <span v-if="props.row.errors.length"> => Scraping type not found</span>
                            </b-table-column>
                        </b-table>
                    </section>
                </div>

            </section>
            <footer class="modal-card-foot p-2">
                <b-button
                    label="Close"
                    @click="showQuickCheckModal = false" />
                <b-button
                    label="Check"
                    type="is-primary"
                    :loading="progressBarModal.loading"
                    @click="quickCheckModal"/>
            </footer>
        </CustomModalWrapper>

        <b-modal v-model="optimizationModalIsOpened" full-screen has-modal-card>
          <div class="modal-card" style="width: auto; background-color: #f8f8f8;">
            <header class="modal-card-head">
              <p class="modal-card-title">Daily Optimization</p>
                <button
                    type="button"
                    class="delete"
                    @click="closeOptimizationModal"/>
            </header>
              <daily-optimization
                  :filter-keyword-name="optimizationModalKeywordName"></daily-optimization>
          </div>
        </b-modal>

    </section>
</template>

<script>
import {
    administrationProductsService,
    devCountriesService,
    partnersService,
    userService,
    advertisersCheckService,
    creationFoundKeywordsService,
} from "@/services";
import {timeUtils} from "@/helpers/time-utils";
import Multiselect from 'vue-multiselect'
import DependantMultiFilter from "@/components/Filter/DependantMultiFilter";
import { mapState } from 'vuex';
import {permissionsUtils} from "@/helpers/permissions-utils";
import CustomModalWrapper from "@/components/CustomModalWrapper";
import moment from "moment";
import Vue from 'vue'
import DailyOptimization from "@/pages/optimization/DailyOptimizationPage.vue";
export default {
    name: 'AdvertisersCheckPage',

    data() {
        return {
            selectedMultiFilter: {
              'marketingUsers': []
            },
            openedDetailed: [],
            openedRow: {},
            autoSelectedRow: {},
            autoSelectedRowList: [],
            showEmptyResultMessage: false,
            advertisersCheck: {
                all: [],
                loading: false,
                paging: {
                    page_size: 0,
                    count: 0,
                    pages: 1,
                    page_number: 1,
                    page_count: 1
                },
            },
            pageSize: 25,
            pageId: 1,
            checkedRows: [],
            keyword_name: '',
            keywords: [],
            advertisers: [],
            status: [],
            statusList: [
                {id: 1, name: 'ENDED', code: 'ENDED,RUNNING_DISPLAY_CHECKED'},
                {id: 2, name: 'FAILED', code: 'FAILED'},
                {id: 3, name: 'PENDING', code: 'QUEUED,DISPATCHED,RUNNING'},
            ],
            selectedUsers: [],
            showPlusModal: false,
            showQuickCheckModal: false,
            plusModalFilter: {
                'networks': [],
            },
            keywordsImported: '',
            quickCheckKeyword: '',
            progressBarModal: {
                show: false,
                total: 0,
                current: 0,
                value: 0,
                currentKeyword: '',
                loading: false,
                data: [],
            },
            scraping_ended_range: [],
            persistence_selection: [],
            trackLoading: [],
            cc: 0,
            localStore: {
                countries: [],
                partners: [],
                products: [],
                users: [],
            },
            timerForUpdateData: null,
            timers: {},
            isLoadingListWithoutId: false,

            optimizationModalIsOpened: false,
            optimizationModalKeywordName: null,
        }
    },

    created() {
        if(!this.users.allMarketing.length) this.$store.dispatch('users/getAllMarketing');
        if(!this.countries.all.length) this.$store.dispatch('countries/getAll');
        if(!this.subdomainLocales.all.length) this.$store.dispatch('subdomainLocales/getAll');
        if(!this.partners.all.length) this.$store.dispatch('partners/getAll');
        if(!this.products.all.length) this.$store.dispatch('products/getAll');
        if(!this.products.allProductsWithAvailableUrlCodes.length) this.$store.dispatch('products/getAllProductsWithAvailableUrlCodes');
        if(!this.networks.all.length) this.$store.dispatch('networks/getAll');

        //set local storage
        administrationProductsService.getAll({}).then(r => {
            this.localStore.products = r.data.results
        })
        devCountriesService.getAll().then(r => {
            this.localStore.countries = r.data.results
        })
        partnersService.getAll().then(r => {
            this.localStore.partners = r.data.results
        })
        userService.getUsers().then(r => {
            this.localStore.users = r.data.results
        })

        //default as today
        this.scraping_ended_range = [
            new Date(),
            new Date()
        ]
    },

    mixins: [
      permissionsUtils,
      timeUtils,
    ],

    components: {
      DailyOptimization,
      DependantMultiFilter,
      Multiselect,
      CustomModalWrapper,
    },

    computed: {
        ...mapState([
            'users',
            'countries',
            'partners',
            'products',
            'networks',
            'subdomainLocales',
        ])
    },

    methods: {
        filterList(pageId, no_loading, pendingId = {}) {
            let id = null;
            //clear persistance auto-select
            this.persistence_selection = []

            pageId = pageId || null;
            if(!no_loading) this.advertisersCheck.loading = true;
            // this.advertisersCheck.all = []
            this.showEmptyResultMessage = false;
            this.checkedRows = []

            let scraping_ended_range = ''
            if(this.scraping_ended_range.length) {
                scraping_ended_range = this.formatDate(this.scraping_ended_range[0], 'YYYY-MM-DD') + ',' + this.formatDate(moment(this.scraping_ended_range[1]).add(1, 'days'), 'YYYY-MM-DD')
            }

            if(pendingId.key) {
                id = pendingId.id;
            }else{
                this.isLoadingListWithoutId = true;
                this.clearAllTimers();
            }

            advertisersCheckService.getCheckedKeywords({
                'keyword__name__in': this.keywords.join(),
                'current_status__in': this.status.map((element) => { return element.code }).join(),
                'network__in': this.selectedMultiFilter['networks'].map((element) => { return element.id }).join(),
                'country__in': this.selectedMultiFilter['countries'].map((element) => { return element.id }).join(),
                'product__in': this.selectedMultiFilter['products'].map((element) => { return element.id }).join(),
                'partner__in': this.selectedMultiFilter['partners'].map((element) => { return element.id }).join(),
                'displayed_advertisers__name__in': this.advertisers.join(),
                'created_by_user__in': this.selectedMultiFilter['marketingUsers'].map((element) => { return element.id }).join(),
                'page_size': this.pageSize,
                'created_on_datetime__range' : scraping_ended_range,
                'page': pageId,
                'id': id,
            }).then((r) => {

              // Ignore requests that happened while you press filter again.
              if(this.isLoadingListWithoutId){
                if(id){
                  return;
                }else{
                  this.isLoadingListWithoutId = false;
                }
              }

                this.showEmptyResultMessage = true;
                // //add custom IDs
                r.data.results.map(r => {
                    r.id = r.key
                    return r
                })

                //set pending scrapers in the head of the list
                let newList = r.data.results.map(m => {
                    let pending_list = [], main_list = []
                    m.scraping_histories.filter(s => {
                        if(!['ENDED', 'FAILED'].includes(s.current_status)) pending_list.push(s)
                        else main_list.push(s)
                    })
                    pending_list.sort((a, b) => {
                        if (this.formatDate(a.scraping_ended_on_datetime, 'YYYY-MM-DD HH:mm') < this.formatDate(b.scraping_ended_on_datetime, 'YYYY-MM-DD HH:mm')) {
                            return 1;
                        } else if (this.formatDate(a.scraping_ended_on_datetime, 'YYYY-MM-DD HH:mm') > this.formatDate(b.scraping_ended_on_datetime, 'YYYY-MM-DD HH:mm')) {
                            return -1;
                        } else {
                            return b.displayed_advertisers_count - a.displayed_advertisers_count;
                        }
                    })
                    main_list.sort((a, b) => {
                        if (this.formatDate(a.scraping_ended_on_datetime, 'YYYY-MM-DD HH:mm') < this.formatDate(b.scraping_ended_on_datetime, 'YYYY-MM-DD HH:mm')) {
                            return 1;
                        } else if (this.formatDate(a.scraping_ended_on_datetime, 'YYYY-MM-DD HH:mm') > this.formatDate(b.scraping_ended_on_datetime, 'YYYY-MM-DD HH:mm')) {
                            return -1;
                        } else {
                            return b.displayed_advertisers_count - a.displayed_advertisers_count;
                        }
                    })
                    m.scraping_histories = [...pending_list, ...main_list]
                    return m
                })

                //update only rows with pending_ids
                if(id && this.advertisersCheck.all.length) {
                    newList = this.advertisersCheck.all.map((a) => {
                        let c = newList.find(b => b.key === a.key)
                        if(c?.key === a.key) {
                            return c
                        } else {
                            return a
                        }
                    })
                }

                // newList = this.advertisersCheck.all;

                if(this.openedDetailed.length) {
                    let a = this.openedDetailed.map(a => a).filter(r=>r)

                    // let key = this.openedDetailed[0]
                    let key = a?.[0]
                    let row = newList.find(a => a.key === key)
                    if(row) {
                        this.openedDetailed = [key]
                        // if(this.openedDetailed.indexOf(key) === -1) this.openedDetailed.push(key)
                        this.onDetailsOpened(row)
                    } else {
                        const self = this
                        for(let i in newList) {
                            if(self.getLevelOneStatus(newList[i]).name === 'PENDING') {
                                self.$nextTick(() => {
                                    self.openedDetailed = [newList[i].key]
                                    // if(self.openedDetailed.indexOf(newList[i].key) === -1) self.openedDetailed.push(newList[i].key)
                                    self.onDetailsOpened(newList[i])
                                })
                                break;
                            }
                        }
                    }
                }

                this.advertisersCheck.all = newList
                this.advertisersCheck.loading = false;
                if(!id) {
                    this.advertisersCheck.paging.page_size = r.data.page_size;
                    this.advertisersCheck.paging.count = r.data.count;
                    this.advertisersCheck.paging.pages = r.data.pages;
                    this.advertisersCheck.paging.page_number = r.data.page_number;
                    this.advertisersCheck.paging.page_count = r.data.page_count;
                    this.autoRefreshPendingScrapes();
                }else{
                    this.autoRefreshPendingScrapes(id);
                }

            }).catch((r) => {
                this.advertisersCheck.loading = false;
                alert('An error occurred while trying to load advertisers Check Data');
                // this.filterList(this.pageId, 'no_loading')
                console.dir(r);
            })

        },

        tableHeight () {
            const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)
            return vh - 175
        },

        closePlusModal(a) {
            this.showPlusModal = a
        },

        onDetailsOpened (row){
            console.log('onDetailsOpened', row)

            this.openedRow = row
            let foundIt = false

            //display selected row
            for(const key in this.persistence_selection) {
                if(this.persistence_selection[key].row_id === row.id && this.persistence_selection[key].page_id === this.pageId) {
                    this.autoSelectedRow = row.scraping_histories.find(s => s.id === this.persistence_selection[key].sub_row_id)
                    // let selectedRow = row.scraping_histories.find(s => s.id === this.persistence_selection[key].sub_row_id)

                    // this.autoSelectedRow = selectedRow
                    // let alreadySelected = this.autoSelectedRowList.find(s => s.id === selectedRow.id)
                    // if(!alreadySelected) this.autoSelectedRowList.push({parent_row: row.key, ...selectedRow})

                    foundIt = true
                    break;
                }
            }

            if(!foundIt) {
                this.autoSelectedRow = this.getLevelTwoRow(row)
                // let selectedRow = this.getLevelTwoRow(row)
                // this.autoSelectedRow = selectedRow
                // let alreadySelected = this.autoSelectedRowList.find(s => s.id === row.id)
                // if(!alreadySelected) this.autoSelectedRowList.push({parent_row: row.key, ...selectedRow})
            }
        },

        getStatusName(row) {
            //If "scraping_started_on_datetime" and "scraping_ended_on_datetime" are both null, the status should be "Pending".
             if(!row.scraping_started_on_datetime && !row.scraping_ended_on_datetime) {
                return 'Pending'

            //If "scraping_started_on_datetime" has a value and "scraping_ended_on_datetime" is null, the status should be "Running".
             } else if(row.scraping_started_on_datetime && !row.scraping_ended_on_datetime) {
                return 'Running'

            //If "scraping_ended_on_datetime" has a value and "is_success" is true, the status should be "Ended".
             } else if(row.scraping_ended_on_datetime && row.is_success) {
                return 'Ended'

            //"If scraping_ended_on_datetime" has a value and "is_success" is false, the status should be "Failed".
             } else if(row.scraping_ended_on_datetime && !row.is_success) {
                return 'Failed'
             }
        },

        newCheck(row, e) {
            e.stopPropagation()
            if(this.trackLoading[row.id]) {
                this.trackLoading[row.id].loadingNewCheck = true
                this.trackLoading[row.id].showMarkNewCheck = false
            } else {
                this.trackLoading[row.id] = {
                    'id': row.id,
                    'page_id': this.pageId,
                    'loadingNewCheck': true,
                    'showMarkNewCheck': false,
                }
            }

            this.getLoading(row, 'loadingNewCheck')
            this.cc += 1

            this.progressBarModal.show = true
            this.progressBarModal.data = []
            this.progressBarModal.total = 1
            this.progressBarModal.value = this.progressBarModal.current = 0
            const products = [...new Set(row.scraping_histories.map(p => p.product))]
            const partners = [...new Set(row.scraping_histories.map(p => p.partner))]

            this.progressBarModal.loading = true;

            this.$buefy.snackbar.open({
                duration: 12000,
                message: `Checking Keywords... ${this.progressBarModal.current} / ${this.progressBarModal.total}<br />Will notify you in a few`,
                queue: false,
                pauseOnHover: true,
            });

            /*
            //manually setting status to pending, while the user waiting
            for(let i in this.advertisersCheck.all) {
                if(this.advertisersCheck.all[i].key === row.key) {
                    this.advertisersCheck.all[i].set_status = {name: 'PENDING', type: 'is-warning'}
                    break;
                }
            }*/

            advertisersCheckService.newCheck({
                'keyword_name': row.keyword_name,
                'network': row.network,
                'countries': [row.country],
                'max_clicks': 1,
                products,
                partners,
            }).then(r => {
                this.$nextTick(() => {
                    Vue.set(this.progressBarModal, 'current', this.progressBarModal.current + 1)
                    Vue.set(this.progressBarModal, 'value', (this.progressBarModal.current / this.progressBarModal.total) * 100)
                    console.log('OK_new_check', r)
                    this.progressBarModal.loading = false;

                    const errors = r.data.map(e => e.error).filter(r=>r)
                    const success = r.data.map(e => {if(!e.error) return e}).filter(r=>r)
                    this.progressBarModal.data.push({success, errors})
                    const errorsMsg = errors.length ? ' => Scraping type not found': ''
                    this.$buefy.snackbar.open({
                        duration: 15000,
                        message: `
                            Checking Keywords... ${ this.progressBarModal.current } / ${ this.progressBarModal.total }
                            <p>Total results: ${success.length + errors.length}</p>
                            <p>Success: ${success.length}</p>
                            <p>Errors: ${ errors.length} ${errorsMsg}</p>
                        `,
                        queue: false,
                        pauseOnHover: true,
                    });
                    this.filterList(null, 'no_loading', [{key: row.key, ids: row.scraping_histories.map(e => e.id).join()}])
                    console.log('2')
                    this.trackLoading[row.id].loadingNewCheck = false
                    this.trackLoading[row.id].showMarkNewCheck = true
                    this.cc += 1
                })
            }).catch((r) => {
                this.$nextTick(() => {
                    Vue.set(this.progressBarModal, 'current', this.progressBarModal.current + 1)
                    Vue.set(this.progressBarModal, 'value', (this.progressBarModal.current / this.progressBarModal.total) * 100)
                    console.log('NOT_OK_new_check', r)
                    this.progressBarModal.loading = false;
                    this.trackLoading[row.id].loadingNewCheck = false
                    this.cc += 1
                });
                console.log('NOT_OK_new_check', r)
                alert('An error occurred while trying to load advertisers Check Data');
                console.dir(r);
            })
        },

        newCheckAll() {
            const self = this
            this.progressBarModal.show = true
            this.progressBarModal.data = []
            this.progressBarModal.total = this.checkedRows.length
            this.progressBarModal.value = this.progressBarModal.current = 0


            this.$buefy.snackbar.open({
                duration: 12000,
                message: `Checking Keywords... ${this.progressBarModal.current} / ${this.progressBarModal.total}<br />Will notify you in a few`,
                queue: false,
                pauseOnHover: true,
            });

            /*
            // manually setting status to pending, while the user waiting
            for(let ii in self.checkedRows) {
                for(let i in self.advertisersCheck.all) {
                    if(self.advertisersCheck.all[i].key === self.checkedRows[ii].key) {
                        self.advertisersCheck.all[i].set_status = {name: 'PENDING', type: 'is-warning'}
                        break;
                    }
                }
            }*/
            let pending_ids = this.checkedRows.map(a => {

                if(!['ENDED', 'FAILED'].includes(this.getLevelOneStatus(a).name)) {
                    const ids = a.scraping_histories.map(s => {
                                if(!['ENDED', 'FAILED'].includes(s.current_status)) {return s.id}
                            }).filter(r=>r).join()
                    return {key: a.key, ids}
                }

            }).filter(r=>r)

            for(let i = 0; i < this.checkedRows.length; i++) {

                let products = [...new Set(self.checkedRows[i].scraping_histories.map(p => p.product))]
                let partners = [...new Set(self.checkedRows[i].scraping_histories.map(p => p.partner))]

                // self.progressBarModal.loading = true;
                self.$nextTick(() => self.progressBarModal.loading = true)
                advertisersCheckService.newCheck({
                    'keyword_name': self.checkedRows[i].keyword_name,
                    'network': self.checkedRows[i].network,
                    'countries': [self.checkedRows[i].country],
                    'max_clicks': 1,
                    products,
                    partners,
                }).then(r => {
                    self.$nextTick(() => {
                        Vue.set(self.progressBarModal, 'current', self.progressBarModal.current + 1)
                        Vue.set(self.progressBarModal, 'value', (self.progressBarModal.current / self.progressBarModal.total) * 100)
                        self.progressBarModal.loading = false;
                        const errors = r.data.map(e => e.error).filter(r=>r)
                        const success = r.data.map(e => {if(!e.error) return e}).filter(r=>r)
                        self.progressBarModal.data.push({success, errors})

                        const errorsMsg = errors.length ? ' => Scraping type not found': ''
                        self.$buefy.snackbar.open({
                            duration: 15000,
                            message: `
                                Checking Keywords... ${ self.progressBarModal.current } / ${ self.progressBarModal.total }
                                <p>Total results: ${success.length + errors.length}</p>
                                <p>Success: ${success.length}</p>
                                <p>Errors: ${ errors.length} ${errorsMsg}</p>
                            `,
                            queue: false,
                            pauseOnHover: true,
                        });

                        // if(self.$store.state.theme.pendingCheckedKeywordsRequests <= 0)
                        self.filterList(null, 'no_loading', pending_ids);
                        console.log('1')
                    })
                }).catch((r) => {
                    self.$nextTick(() => {
                        Vue.set(self.progressBarModal, 'current', self.progressBarModal.current + 1)
                        Vue.set(self.progressBarModal, 'value', (self.progressBarModal.current / self.progressBarModal.total) * 100)
                        self.progressBarModal.loading = false;
                        // self.trackLoading[self.checkedRows[i].id].loadingNewCheck = false
                        // self.cc += 1
                        console.log(i,'NOT_OK_new_check', r)
                    })
                    alert('An error occurred while trying to load advertisers Check Data');
                    console.dir(r);
                })
            }
        },

        plusModalCheck() {
            if(!this.plusModalFilter['networks'].length) {
               return alert('You must select a network.')
            }
            if(!this.plusModalFilter['subdomainLocales'].length) {
               return alert('You must select a country.')
            }
            if(!this.keywordsImported) {
               return alert('You must type a keyword.')
            }

            const self = this
            const countries = this.plusModalFilter['subdomainLocales'].map((element) => { return element.country_id })
            const products = this.plusModalFilter['productsWithAvailableUrlCodes'].map((element) => { return element.id })
            const network = this.plusModalFilter['networks'].map((element) => { return element.id })[0]
            const partners = this.plusModalFilter['availablePartners'].map((element) => { return element.id })
            const keywords = this.keywordsImported.split(/[\n\t,;]+/).filter(r=>r)

            //rest the table
            this.keywordsImported = ''

            this.progressBarModal.show = true
            this.progressBarModal.data = []
            this.progressBarModal.total = keywords.length
            this.progressBarModal.value = this.progressBarModal.current = 0

            this.$buefy.snackbar.open({
                duration: 12000,
                message: `Checking Keywords... ${this.progressBarModal.current} / ${this.progressBarModal.total}<br />Will notify you in a few`,
                queue: false,
                pauseOnHover: true,
            });

            for(let i = 0; i < keywords.length; i++) {
                self.progressBarModal.currentKeyword = keywords[i]

                advertisersCheckService.newCheck({
                    keyword_name: keywords[i].trim(),
                    'max_clicks': 1,
                    network,
                    countries,
                    products,
                    partners,
                })
                .then(r => {
                    self.$nextTick(() => {
                        Vue.set(self.progressBarModal, 'current', self.progressBarModal.current + 1)
                        Vue.set(self.progressBarModal, 'value', (self.progressBarModal.current / self.progressBarModal.total) * 100)
                        self.progressBarModal.loading = false;
                        const errors = r.data.map(e => e.error).filter(r=>r)
                        const success = r.data.map(e => {if(!e.error) return e}).filter(r=>r)
                        self.progressBarModal.data.push({success, errors})
                        console.log(i,'OK_new_check', r)

                        const errorsMsg = errors.length ? ' => Scraping type not found': ''
                        this.$buefy.snackbar.open({
                            duration: 15000,
                            message: `
                                Checking Keywords... ${ self.progressBarModal.current } / ${ self.progressBarModal.total }
                                <p>Total results: ${success.length + errors.length}</p>
                                <p>Success: ${success.length}</p>
                                <p>Errors: ${ errors.length} ${errorsMsg}</p>
                            `,
                            queue: false,
                            pauseOnHover: true,
                        });

                        countries.every(c => {
                        let rowExist = self.advertisersCheck.all.find(a => a.keyword_name === keywords[i].trim() && a.network === network && a.country === c)
                        if(rowExist) {
                            for(let i in self.advertisersCheck.all) {
                                if(self.advertisersCheck.all[i].key === rowExist.key) {
                                    self.advertisersCheck.all[i].set_status = {name: 'PENDING', type: 'is-warning'}
                                    break;
                                }
                            }
                        } else {
                            //add new row
                            self.advertisersCheck.all.splice(0, 0, {
                                country: c,
                                network,
                                keyword_name: keywords[i].trim(),
                                created_by_user: self.$store.state.theme.userId,
                                key: null,
                                set_status: {name: 'PENDING', type: 'is-warning'},
                                last_checked_on_datetime: new Date(),
                                last_created_on_datetime: new Date(),
                                scraping_histories: r.data,
                            })

                        }
                    })

                        // if(self.$store.state.theme.pendingCheckedKeywordsRequests <= 0)
                        self.filterList(null, 'no_loading')
                        console.log('3')
                    })
                }).catch((r) => {
                    self.$nextTick(() => {
                        Vue.set(self.progressBarModal, 'current', self.progressBarModal.current + 1)
                        Vue.set(self.progressBarModal, 'value', (self.progressBarModal.current / self.progressBarModal.total) * 100)
                        self.progressBarModal.loading = false;
                        console.log(i,'NOT_OK_new_check', r)
                    })
                    alert('An error occurred while trying to load advertisers Check Data');
                    console.dir(r);
                })
            }

        },

        quickCheckModal() {
            if(!this.plusModalFilter['networks'].length) {
               return alert('You must select a network.')
            }
            if(!this.plusModalFilter['productsWithAvailableUrlCodes'].length) {
               return alert('You must select a product.')
            }
            if(!this.plusModalFilter['subdomainLocales'].length) {
               return alert('You must select a country.')
            }
            if(!this.quickCheckKeyword) {
               return alert('You must type a keyword.')
            }

            const countries = this.plusModalFilter['subdomainLocales'].map((element) => { return element.country_id })
            const products = this.plusModalFilter['productsWithAvailableUrlCodes'].map((element) => { return element.id })
            const network = this.plusModalFilter['networks'].map((element) => { return element.id })[0]
            const keywords = this.quickCheckKeyword.trim()


            //manually add the keyword till the server response
            const self = this
            let rowExist = this.advertisersCheck.all.find(a => a.keyword_name === keywords && a.network === network && a.country === countries[0])
            if(rowExist) {
                for(let i in this.advertisersCheck.all) {
                    if(self.advertisersCheck.all[i].key === rowExist.key) {
                        self.advertisersCheck.all[i].set_status = {name: 'PENDING', type: 'is-warning'}
                        break;
                    }
                }
            } else {
                //add new row
                this.advertisersCheck.all.splice(0, 0, {
                    country: countries[0],
                    network,
                    keyword_name: keywords,
                    created_by_user: this.$store.state.theme.userId,
                    key: new Date(),
                    set_status: {name: 'PENDING', type: 'is-warning'},
                    last_checked_on_datetime: new Date(),
                    last_created_on_datetime: new Date(),
                    scraping_histories: [],
                })

            }

            //rest the table
            this.keywordsImported = ''

            this.progressBarModal.show = true
            this.progressBarModal.data = []
            this.progressBarModal.total = 1
            this.progressBarModal.value = this.progressBarModal.current = 0
            this.progressBarModal.loading = true
            this.progressBarModal.keyword_name = keywords

            this.$buefy.snackbar.open({
                duration: 12000,
                message: `Checking Keywords... ${this.progressBarModal.current} / ${this.progressBarModal.total}<br />Will notify you in a few`,
                queue: false,
                pauseOnHover: true,
            });

                this.progressBarModal.currentKeyword = keywords
                advertisersCheckService.newCheck({
                    keyword_name: keywords,
                    network,
                    countries,
                    products,
                    'max_clicks': 1,
                    'partners': [],
                    'is_sync': true,
                })
                .then(r => {
                    this.$nextTick(() => {
                        Vue.set(this.progressBarModal, 'current', this.progressBarModal.current + 1)
                        Vue.set(this.progressBarModal, 'value', (this.progressBarModal.current / this.progressBarModal.total) * 100)
                        this.progressBarModal.loading = false;
                        const errors = r.data.map(e => e.error).filter(r=>r)
                        const success = r.data.map(e => {if(!e.error) return e}).filter(r=>r)
                        this.progressBarModal.data.push({success, errors})

                        const errorsMsg = errors.length ? ' => Scraping type not found': ''
                        this.$buefy.snackbar.open({
                            duration: 15000,
                            message: `
                                Checking Keywords... ${ this.progressBarModal.current } / ${ this.progressBarModal.total }
                                <p>Total results: ${success.length + errors.length}</p>
                                <p>Success: ${success.length}</p>
                                <p>Errors: ${ errors.length} ${errorsMsg}</p>
                            `,
                            queue: false,
                            pauseOnHover: true,
                        });

                        let pending_ids = r.data?.results?.map(a => {

                            if(!['ENDED', 'FAILED'].includes(this.getLevelOneStatus(a).name)) {
                                const ids = a.scraping_histories.map(s => {
                                            if(!['ENDED', 'FAILED'].includes(s.current_status)) {return s.id}
                                        }).filter(r=>r).join()
                                return {key: a.key, ids}
                            }
                        }).filter(r=>r)

                        this.quickCheckKeyword = ''
                        this.filterList(null, 'no_loading', pending_ids);
                        console.log('4')
                    })
                }).catch((r) => {
                    this.$nextTick(() => {
                        Vue.set(this.progressBarModal, 'current', this.progressBarModal.current + 1)
                        Vue.set(this.progressBarModal, 'value', (this.progressBarModal.current / this.progressBarModal.total) * 100)
                        this.progressBarModal.loading = false;
                        console.log('NOT_OK_new_check', r)
                    })
                    alert('An error occurred while trying to load advertisers Check Data');
                    console.dir(r);
                })

        },

        launch(row, e) {

            e.stopPropagation()
            if(this.trackLoading[row.id]) {
                this.trackLoading[row.id].subRowLoadingLaunch = true
                this.trackLoading[row.id].subRowShowMarkLaunch = false
                this.trackLoading[row.id].subRowDisableLaunch = false
            } else {
                this.trackLoading[row.id] = {
                    'id': row.id,
                    'page_id': this.pageId,
                    'subRowLoadingLaunch': true,
                    'subRowShowMarkLaunch': false,
                    'subRowDisableLaunch': false,
                }
            }

            this.getLoading(row, 'subRowLoadingLaunch')
            this.cc += 1

            const payload = {
                "keyword_name": row.keyword_name,
                "country": row.country,
                'foundkeywordnetworks': [{'network': row.network}],
                "sources": [1],
                "languages": [row.language],
                'subdomain_locales': [row.subdomain_locale],
            }
            creationFoundKeywordsService.post(payload)
            .then(() => {
                this.$nextTick(() => {
                    this.filterList(null, 'no_loading', [{key: this.openedRow.key, ids: row.id}])
                    console.log('5')
                    this.trackLoading[row.id].subRowLoadingLaunch = false
                    this.trackLoading[row.id].subRowShowMarkLaunch = true
                    this.trackLoading[row.id].subRowDisableLaunch = true
                    this.cc += 1
                })
            },
            error =>
            {
                this.$nextTick(() => {
                    this.advertisersCheck.loading = false
                    this.trackLoading[row.id].loadingLaunch = false
                    this.cc += 1
                    alert('An Error Occurred 1');
                    console.error(error);
                })
            })
        },

        launchAll() {
            const self = this
            // this.advertisersCheck.loading = true
            for(let i = 0; i < this.checkedRows.length; i++) {
                //use max ads_count of each row
                let levelTwoRow = {}
                let foundIt = false

                for(const key in self.persistence_selection) {
                    if(self.persistence_selection[key].row_id === this.checkedRows[i].id && self.persistence_selection[key].page_id === self.pageId) {
                        levelTwoRow = this.checkedRows[i].scraping_histories.find(s => s.id === self.persistence_selection[key].sub_row_id)
                        foundIt = true
                        break;
                    }
                }
                if(!foundIt) levelTwoRow = this.getLevelTwoRow(this.checkedRows[i])

                let payload = {
                    "keyword_name": this.checkedRows[i].keyword_name,
                    "country": this.checkedRows[i].country,
                    'foundkeywordnetworks': [{'network': this.checkedRows[i].network}],
                    "sources": [1],
                    "languages": [levelTwoRow.language],
                    'subdomain_locales': [levelTwoRow.subdomain_locale],
                }

                creationFoundKeywordsService.post(payload)
                .then(() => {
                    self.$nextTick(() => {

                        if(i === self.checkedRows.length - 1) {
                            self.openedDetailed = []
                            let pending_ids = this.checkedRows.map(a => {
                                if(!['ENDED', 'FAILED'].includes(this.getLevelOneStatus(a).name)) {
                                    const ids = a.scraping_histories.map(s => {
                                                if(!['ENDED', 'FAILED'].includes(s.current_status)) {return s.id}
                                            }).filter(r=>r).join()
                                    return {key: a.key, ids}
                                }
                            }).filter(r=>r)
                            self.filterList(null, 'no_loading', pending_ids);
                            console.log('6');
                        }
                    })
                },
                error =>
                {
                    self.$nextTick(() => {

                        console.log(i, 'NOT_OK')
                        alert('An Error Occurred 1');
                        console.error(error);
                    })
                })
            }
        },

        captureTab(e){
            let target = e.target,
                char = "\t";

            if (target.setRangeText) {
                //if setRangeText function is supported by current browser
                target.setRangeText(char,
                                    target.selectionStart,
                                    target.selectionEnd,
                                    'end');
            } else {
                // Deprecated, so only used when setRangeText is not available.
                target.focus()
                document.execCommand('insertText', false /*no UI*/, char);
            }
        },

        getLevelTwoRow(row) {
            //the row with the most recent date, and then maximum advertiser count
            let maxDate = this.formatDate(Math.max(...row.scraping_histories.map(o => new Date(o.scraping_ended_on_datetime))), 'YYYY-MM-DD HH:mm')
            let newList = row.scraping_histories.map(s => {if(this.formatDate(s.scraping_ended_on_datetime, 'YYYY-MM-DD HH:mm') === maxDate) return s}).filter(r=>r)
            let maxAdsCounterValue = Math.max(...newList.map(o => o.displayed_advertisers_count))
            return newList.find(s => s.displayed_advertisers_count === maxAdsCounterValue)
        },

        getCountry(id) {
            return this.localStore.countries.find(c => c.id === id)?.name
        },

        getPartner(id) {
            const partners = this.localStore.partners.find(p => p.id === id)
            return partners?.[this.getFieldCodeLabel()];
        },

        getProduct(id) {
            return this.localStore.products.find(p => p.id === id)?.[this.getFieldNameLabel()];
        },

        getNetwork(id) {
            return this.networks.all.find(n => n.id === id)?.name
        },

        getUser(id) {
            return this.localStore.users.find(p => p.id === id)?.username
        },

        changePage(pageId){
            this.persistence_selection = this.openedDetailed = []
            this.pageId = pageId
            this.filterList(pageId);
            console.log('7');
        },

        getStatus(row) {
            const name = ['ENDED', 'FAILED'].includes(row.current_status) ? row.current_status : 'PENDING'
            let type = ''

            if(name === 'ENDED') {type = 'is-success'}
            else if (name === 'FAILED') {type = 'is-danger'}
            else {type = 'is-warning'}

            return {
                name,
                type,
            }
        },

        getLevelOneStatus(row) {

            if(row.set_status) {return row.set_status}
            let res
            //at least one is pending
            res = row.scraping_histories.find(e => !['ENDED', 'FAILED'].includes(e.current_status))
            if(res) {
                return {name: 'PENDING', type: 'is-warning'}
            } else {
                // all FAILED
                res = row.scraping_histories.map(e => e.current_status === 'FAILED').filter(r => r)
                if(res?.length === row?.scraping_histories.length) {
                    return {name: 'FAILED', type: 'is-danger'}

                    //ended
                } else {
                    return {name: 'ENDED', type: 'is-success'}
                }
            }
        },

        getLoading(row, type) {
            return this.trackLoading[row.id]?.[type]
        },

        makeClickableRow(e) {
            // const index = this.openedDetailed.indexOf(e.key)
            // if(index !== -1) {
            //     console.log('del', e)
            //     this.openedDetailed.splice(index, 1)
            // } else {
            //     console.log('add', e)
            //     this.openedDetailed.push(e.key)
            // }
            this.onDetailsOpened(e)
            if(this.openedDetailed.indexOf(e.id) > -1) return this.openedDetailed = []


            this.openedDetailed.push(e.id)
            this.onDetailsOpened(e)
            // const self = this
            // let row =
            // const self = this
            // for(let i in this.autoSelectedRowList) {
            //     if(self.autoSelectedRowList[i].parent_row === row.parent_row && self.autoSelectedRowList[i].id === row.id) {
            //         self.autoSelectedRowList.splice(i, 1)
            //         return ''
            //     } else {
            //         return this.autoSelectedRowList.find(s => s.id === row.id) && 'is-selected'
            //     }
            // }



            // const self = this
            // for(let key in this.autoSelectedRowList) {
            //     let pr = self.advertisersCheck.all.find(a => a.key === this.autoSelectedRowList[key].parent_row)
            //     // self.onDetailsOpened(pr)
            //     console.log('pr', pr)
            // //     if(this.autoSelectedRowList[key].parent_row === e.id) {
            // //         console.log('pr',pr)
            // //     }
            //     let row = pr
            //     let foundIt = false

            //     //display selected row
            //     for(const key in self.persistence_selection) {
            //         if(self.persistence_selection[key].row_id === row.id && self.persistence_selection[key].page_id === self.pageId) {
            //             let selectedRow = row.scraping_histories.find(s => s.id === self.persistence_selection[key].sub_row_id)

            //             this.autoSelectedRow = selectedRow
            //             let alreadySelected = self.autoSelectedRowList.find(s => s?.id === selectedRow?.id)
            //             if(!alreadySelected) self.autoSelectedRowList.push({parent_row: row.key, ...selectedRow})

            //             foundIt = true
            //             break;
            //         }
            //     }
            //     console.log(foundIt)

            //     // if(!foundIt) {

            //     //     let selectedRow = this.getLevelTwoRow(row)
            //     //     this.autoSelectedRow = selectedRow
            //     //     let alreadySelected = self.autoSelectedRowList.find(s => s.id === row.id)
            //     //     if(!alreadySelected) self.autoSelectedRowList.push({parent_row: row.key, ...selectedRow})
            //     // }




            // }




        },

        showLaunchBtn(row) {
            const self = this
            let showActions = true
            for(let i in this.advertisersCheck.all) {
                for(let ii in self.advertisersCheck.all[i].scraping_histories) {
                    if(self.advertisersCheck.all[i].scraping_histories[ii].id == row.id) {
                        if(self.advertisersCheck.all[i].is_launched || self.advertisersCheck.all[i].is_live) {
                            showActions = false
                        }
                        break;
                    }
                }
            }
            return showActions
        },

        expandAll() {
            if(this.openedDetailed.length === this.advertisersCheck.all.length) {
                this.openedDetailed = []
                this.$nextTick(() => {
                    this.tableHeight()
                })
            } else {
                this.openedDetailed = this.advertisersCheck.all.map(a => a.key)
                this.$nextTick(() => {
                    this.tableHeight()
                })
            }
        },

        autoRefreshPendingScrapes(onlyId) {

              let that = this,
                  pendingIds = this.advertisersCheck.all.map(a => {
                if(!['ENDED', 'FAILED'].includes(this.getLevelOneStatus(a).name)) {
                    const ids = a.scraping_histories.map(s => {
                                if(!['ENDED', 'FAILED'].includes(s.current_status)) {
                                  return s.id
                                }
                            }).filter(r=>r)
                    return {key: a.key, ids}
                }
            }).filter(r=>r);

              for(let i in pendingIds) {
                let pendingId = pendingIds[i];

                for(let ii in pendingId.ids){
                   let id = pendingId.ids[ii];

                   if(onlyId && onlyId !== id){
                     continue;
                   }
                   if(that.timers[id]){
                     clearTimeout(that.timers[id].id);
                   }
                   that.timers[id] = {}
                   that.timers[id].id = setTimeout(() => {
                                  console.dir({key: pendingId.key, id: id}, 'ids');
                                  console.log('should filter');
                                  that.timers[id].request = that.filterList(null, 'no_loading', {key: pendingId.key, id: id});
                                  console.log('8');
                                }, 5000);
                }
              }

            // this.timers = setInterval(() => {
            //
            //
            //     pendingIdsDate = pendingIdsDate.sort((a, b) => { return moment(a.date).isAfter(moment(b.date)) });
            //
            //     console.dir(pendingIdsDate, 'pendingIdsDate');
            //
            //     if(pendingIdsDate.length > 5){
            //       pendingIdsDate = pendingIdsDate.slice(0, 5);
            //       console.dir(pendingIdsDate, 'pendingIdsDateSliced');
            //     }
            //
            //     const pending_ids = pendingIdsDate.map(a => {
            //       return a.id
            //     });
            //     if (pending_ids.length) {
            //       console.dir(pending_ids, 'ids');
            //       console.log('should filter');
            //       // this.filterList(null, 'no_loading', pending_ids);
            //       console.log('8');
            //     }
            // }, 5000)
        },

        setSelectedClass() {
            // for(let key in openedDetailed) {
            //     let a =
            // }
            // console.log('class__',row)
            // let list =
            // for(let key in this.autoSelectedRowList) {
            //     if(this.autoSelectedRowList[key].parent_row === row.parent_row) {
            //         this.autoSelectedRowList.splice(key, 1)
            //     }
            // }

            // this.autoSelectedRowList.push(row)
            // const self = this
            // for(let i in this.autoSelectedRowList) {
            //     if(self.autoSelectedRowList[i].parent_row === row.parent_row && self.autoSelectedRowList[i].id === row.id) {
            //         self.autoSelectedRowList.splice(i, 1)
            //         return ''
            //     } else {
            //         return this.autoSelectedRowList.find(s => s.id === row.id) && 'is-selected'
            //     }
            // }
        },

        clearAllTimers(){
          for(let i in this.timers){
            clearInterval(this.timers[i].id);
            console.dir(this.timers[i].request)
          }
        },

        openOptimizationModal(keywordName) {
          this.optimizationModalIsOpened = true;
          this.optimizationModalKeywordName = keywordName;
        },

        closeOptimizationModal() {
          this.optimizationModalIsOpened = false;
        },
    },

    watch: {
        autoSelectedRow(val) {
            this.persistence_selection[this.openedRow?.id] = {
                'row_id': this.openedRow?.id,
                'sub_row_id': val?.id,
                'page_id': this.pageId,
            }
        },

        autoSelectedRowList(val) {
            console.log('list change', val)
            this.cc += 1
            const self = this
            for(let key in val) {
                self.persistence_selection[val[key]?.id] = {
                    'row_id': val[key]?.parent_row,
                    'sub_row_id': val[key]?.id,
                    'page_id': self.pageId,
                }
            }
        },
    },

    beforeDestroy() {
      this.clearAllTimers();
    },

    showPlusModal() {
        this.$emit('close', this.showPlusModal)
    }
}
</script>