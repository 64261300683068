<template>
<div id="app">
  <SystemUnderMaintenance v-if="$store.state.theme.systemUnderMaintenance" />
  <template v-else>
    <div v-if="alert.message" :class="`has-text-centered notification is-light is-${alert.type}`">{{alert.message}}</div>
    <nav-bar v-show="loggedIn" />
    <aside-menu :menu="menu" v-show="loggedIn"  />
    <router-view />
    <footer-bar />
  </template>
</div>
</template>

<script>

import NavBar from '@/components/NavBar'
import AsideMenu from '@/components/AsideMenu'
import FooterBar from '@/components/FooterBar'
import SystemUnderMaintenance from '@/components/SystemUnderMaintenance';

export default {
    name: 'app',
    components: {
      FooterBar,
      AsideMenu,
      NavBar,
      SystemUnderMaintenance,
    },
    computed: {
        alert () {
            return this.$store.state.alert
        },
        loggedIn () {
          let loggedIn = this.$store.state.authentication.status.loggedIn;

          if(!loggedIn){
            this.$store.commit('theme/asideMobileStateHide');
          }

          return loggedIn;
        },
      menu () {
      return {
        'General': [
          {
            to: '/',
            icon: 'chart-bar',
            label: 'Dashboard',
            visibleTo: ['IsSuperUser', 'IsManagement', 'IsMarketing']
          },
          // {
          //   to: '/optimization',
          //   icon: 'account-tie',
          //   label: 'Optimization',
          //   visibleTo: ['IsSuperUser', 'IsMarketing']
          // },
          {
            to: '/daily-optimization',
            icon: 'monitor-dashboard',
            label: 'Daily Optimization',
            visibleTo: ['IsSuperUser', 'IsMarketing']
          },
          {
            to: '/daily-performance-analysis',
            icon: 'monitor-dashboard',
            label: 'Daily Performance Analysis',
            visibleTo: ['IsSuperUser', 'IsMarketing']
          },
          {
            to: '/performance-viewer',
            icon: 'account-tie',
            label: 'Performance Viewer',
            visibleTo: ['IsSuperUser', 'IsInternalAgency']
          },
          {
            to: '/external-agencies-revenue',
            icon: 'account-tie',
            label: 'External Agencies (Revenues)',
            visibleTo: ['IsSuperUser', 'IsBusinessDeveloper']
          },
          {
            to: '/optimization-automation',
            icon: 'account-tie',
            label: 'Optimization Automation',
            visibleTo: ['IsSuperUser']
          },
          {
            to: '/marketing-agency',
            icon: 'account-tie',
            label: 'Marketing Agency',
            visibleTo: ['IsSuperUser', 'IsBusinessDeveloper', 'IsDev']
          },
          {
            to: '/marketing-agency-users',
            icon: 'account-tie',
            label: 'Marketing Agency Users',
            visibleTo: ['IsSuperUser', 'IsOrganizationAdmin']
          },
          {
            to: '/access-groups',
            icon: 'account-tie',
            label: 'Access Groups',
            visibleTo: ['IsSuperUser', 'IsOrganizationAdmin']
          }
        ],
        'Administration': [
            {
              to: '/tracking-codes',
              icon: 'all-inclusive',
              label: 'Tracking Codes',
              visibleTo: ['IsSuperUser']
            },
            {
              to: '/launching-settings',
              icon: 'rocket-launch',
              label: 'Launching Settings',
              visibleTo: ['IsSuperUser', 'IsMarketing']
            }
        ],
        'Tools': [
              {
                to: '/image-searcher',
                icon: 'tooltip-image-outline',
                label: 'Image Searcher',
                visibleTo: ['IsSuperUser']
              },
              {
                to: '/keywords-life',
                icon: 'axis-arrow-info',
                label: 'Keywords Life',
                visibleTo: ['IsSuperUser', 'IsDev', 'IsMarketing', 'IsMarketingUntrusted']
              },
              {
                to: '/performance-analysis',
                icon: 'brain',
                label: 'Performance Analysis',
                visibleTo: ['IsSuperUser']
              },
              {
                to: '/tq-analysis',
                icon: 'alarm-light-outline',
                label: 'TQ Analysis',
                visibleTo: ['IsSuperUser']
              }
            ],
            'Reporting': [
              {
                to: '/optimization-analysis',
                icon: 'monitor-dashboard',
                label: 'Optimization Analysis',
                visibleTo: ['IsSuperUser', 'IsDev']
              },
              {
                to: '/dashboard-image-picker',
                icon: 'auto-fix',
                label: 'Image Picker',
                visibleTo: ['IsSuperUser']
              },
              {
                to: '/dashboard-traffic-chart',
                icon: 'auto-fix',
                label: 'Traffic Chart',
                visibleTo: ['IsSuperUser', 'IsMarketing']
              }
            ],
            'Pipelines': [
              {
                to: '/search-terms',
                icon: 'bacteria-outline',
                label: 'Search Terms',
                visibleTo: ['IsSuperUser']
              },
              {
                to: '/facebook-inbox',
                icon: 'facebook',
                label: 'Facebook Inbox',
                visibleTo: ['IsSuperUser']
              },
              {
                to: '/facebook-disapproved-ads',
                icon: 'facebook',
                label: 'Facebook Disapproved Ads',
                visibleTo: ['IsSuperUser']
              },
              {
                to: '/facebook-compliance-review',
                icon: 'facebook',
                label: 'Compliance Review',
                visibleTo: ['IsSuperUser', 'IsComplianceReviewer']
              }
            ],
            'Found Keyword': [
              {
                to: '/prevalidate-keywords',
                icon: 'account-reactivate',
                label: 'Prevalidate Keywords',
                visibleTo: ['IsSuperUser']
              },{
                to: '/url-builder',
                icon: 'web-clock',
                label: 'Url Builder',
                visibleTo: ['IsSuperUser', 'IsMarketing'],
                hideFrom: ['IsMarketingUntrusted'],
              },
              {
                to: '/manual-import',
                icon: 'account-reactivate',
                label: 'Manual Import',
                visibleTo: ['IsSuperUser', 'IsMarketing']
              },
              {
                to: '/no-images-found-keywords',
                icon: 'arrow-decision',
                label: 'Image Selection',
                visibleTo: ['IsSuperUser', 'IsImagePicker', 'IsMarketing']
              },
              {
                to: '/launching-queue',
                icon: 'tray-full',
                label: 'Launching Queue',
                visibleTo: ['IsSuperUser', 'IsMarketing']
              },
              {
                to: '/advertisers-check',
                icon: 'tray-full',
                label: 'Advertisers Check',
                visibleTo: ['IsSuperUser', 'IsMarketing']
              },
            ],
            'DEV': [
              {
                to: '/tracking-codes',
                icon: 'account-tie',
                label: 'Dev',
                visibleTo: ['IsDev']
              },
              {
                to: '/super-login',
                icon: 'account-tie',
                label: 'Log-in as..',
                visibleTo: ['IsSuperUser', 'IsBusinessDeveloper']
              },
            ],
      }
    }
    },
    watch:{
        $route (){
          // clear alert on location change
          this.$store.dispatch('alert/clear');
          this.$store.commit('theme/asideMobileStateHide');
          this.$store.dispatch('theme/getUserDetails');
        }
    }
};
</script>

<style scoped>
.notification {
  max-width: 50%;
  margin: 30px auto;
}
</style>
