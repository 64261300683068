import Vue from 'vue'
import App from './App.vue'
import Buefy from 'buefy';
// import 'buefy/dist/buefy.css';
import '@/scss/main.scss'
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";

Vue.use(Buefy);

Vue.config.productionTip = false

import { store } from './store';
import { router } from './helpers';
import { refreshToken } from '@/services'
import axios from "axios";

axios.defaults.headers.common["Accept"] = 'application/json';
axios.defaults.headers.common["Content-Type"] = 'application/vnd.example.v1+json';

axios.interceptors.request.use(config => {
  //setup auth header
  config.headers['Authorization'] = 'Bearer ' + JSON.parse(localStorage.getItem('user'))?.access

  if(config.url.indexOf('api/token') && JSON.parse(localStorage.getItem('__TRUST_CODE__'))) {
    config.headers['trust-code'] = JSON.parse(localStorage.getItem('__TRUST_CODE__'))
  }
  
  //remove additional param vue added on keyboard events
  if(config.params?.page?.isTrusted) config.params.page = null

  if(config.url.indexOf('api/proxy/peter-parker/checked-keywords'))
    store.commit('theme/set', ['pendingCheckedKeywordsRequests', store.state.theme.pendingCheckedKeywordsRequests + 1])
  
  return config
})

axios.interceptors.response.use( (response) => {

  if(response.config.url.indexOf('api/proxy/peter-parker/checked-keywords'))
    store.commit('theme/set', ['pendingCheckedKeywordsRequests', store.state.theme.pendingCheckedKeywordsRequests - 1])

    // Return a successful response back to the calling service
    return response;
  }, (error) => {

    if(error?.config?.url.indexOf('api/proxy/peter-parker/checked-keywords'))
      store.commit('theme/set', ['pendingCheckedKeywordsRequests', store.state.theme.pendingCheckedKeywordsRequests - 1])

    //catch login error
    if(error?.config?.url.indexOf('api/token') && error?.response.data?.msg) {
      return new Promise((resolve, reject) => {
        reject(error);
      }); 
    }

    // Return any error which is not due to authentication back to the calling service
    if (error.response.status !== 403 && error.response.status !== 401) {
      return new Promise((resolve, reject) => {
        reject(error);
      });
    }

    // Logout user if token refresh didn't work or user is disabled
    if (error.response.data.code === 'token_not_valid') {

      // TokenStorage.clear();
      router.push({ name: 'login' }).catch(() => {});

      return new Promise((resolve, reject) => {
        reject(error);
      });
    }

    // Try request again with new token
    return refreshToken()
      .then((token) => {

        // New request with new token
        const config = error.config;
        config.headers['Authorization'] = `Bearer ${token}`;

        return new Promise((resolve, reject) => {
          axios.request(config).then(response => {
            resolve(response);
          }).catch((error) => {
            reject(error);
          })
        });

      })
      .catch((error) => {
        return Promise.reject(error);
      });
});

//Setup Sentry SDK
Sentry.init({
  Vue,
  dsn: "https://72b91184c1154d6b87b958514fca3cd5@o4504530839666688.ingest.sentry.io/4504530843205632",
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracePropagationTargets: ["localhost", "mozart.pytech.ai", /^\//],
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  logErrors: process.env.NODE_ENV === 'development',
  environment: process.env.NODE_ENV,
  release: 'mozart-frontend@' + require('../package.json').version,
  attachStacktrace: true,
  attachProps: true,
});

new Vue({
  render: h => h(App),
  router,
  store
}).$mount('#app')
