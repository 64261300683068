<template>
  <div>
    <b-loading v-model="isLoading"></b-loading>
    <div class="modal-card" style="width: auto">
      <header class="modal-card-head">
        {{ keywordName }}
      </header>
      <section class="modal-card-body p-3">
        <div v-if="networkCode === 'OBN'">
          <div class="columns mt-1">
            <div class="column">
              <b-field label="Campaign Status">
                <b-select v-model="OBN.settings.campaign_status">
                  <option v-for="s in OBN.statuses" :value="s.value" v-bind:key="`campaign-status-${s.value}`">
                    {{ s.label }}
                  </option>
                </b-select>
              </b-field>
              <b-field
                  label="Device Criteria">
                <multiselect
                    v-model="OBN.settings.device_criteria"
                    :options="OBN.deviceCriteria"
                    :multiple="true"
                    :close-on-select="true"
                    placeholder="Device Criteria"
                    :disable="OBN.deviceCriteria.length === 0">
                </multiselect>
              </b-field>

              <b-field :label="`Bid (${OBN.settings.account_currency_code}):`">
                <b-select v-model="OBN.settings.bidding_strategy">
                  <option
                      v-for="option in biddingStrategies['allOBN']"
                      :key="option.id"
                      :value="option.id">
                    {{ option.code }}
                  </option>
                </b-select>
                <decimal-input
                    v-model="OBN.settings.bid_amount"
                    step="0.01"
                    :ref="'bid_'"
                    :disabled="!isBidHandled(OBN.settings.bidding_strategy)"
                    controls-alignment="right"></decimal-input>
              </b-field>

              <b-field :label="`Budget (${OBN.settings.account_currency_code}):`">
                <decimal-input v-model="OBN.settings.budget_amount"
                               step="1"
                               only-integers
                               controls-alignment="right"></decimal-input>
              </b-field>

            </div>
          </div>
        </div>
        <div v-else-if="networkCode === 'FBA'">
          <div class="columns mt-1">
            <div class="column">

              <b-field label="AdSet Status">
                <b-select v-model="FBA.settings.adset_status">
                  <option v-for="s in FBA.statuses" :value="s.value" v-bind:key="`fba-ad-group-status-${s.value}`">
                    {{ s.label }}
                  </option>
                </b-select>
              </b-field>

              <b-field label="Delivery Method">
                <b-select v-model="FBA.settings.delivery_method">
                  <option v-for="s in FBA.facebookAttributes['DeliveryMethod']" :value="s"
                          v-bind:key="`fba-delivery-method-${s}`">
                    {{ s }}
                  </option>
                </b-select>
              </b-field>

              <b-field label="Age">
                <b-input v-model="FBA.settings.min_age" type="number"/>
                <b-input v-model="FBA.settings.max_age" type="number"/>
              </b-field>

              <b-field
                  label="Device Criteria"
                  :type="{ 'is-danger': FBA.settings.deviceCriteriaHasError }"
                  :message="{ 'Please provide select at least one value': FBA.settings.deviceCriteriaHasError }">
                <multiselect
                    v-model="FBA.settings.device_criteria"
                    :options="FBA.deviceCriteria"
                    :multiple="true"
                    :close-on-select="true"
                    placeholder="Device Criteria"
                    :disable="FBA.deviceCriteria.length === 0"
                    label="code"
                    track-by="id">
                </multiselect>
              </b-field>

              <b-field
                  label="Publisher Platforms"
                  :type="{ 'is-danger': FBA.settings.publisherPlatformsHasError }"
                  :message="{ 'Please provide select at least one value': FBA.settings.publisherPlatformsHasError }">
                <multiselect
                    v-model="FBA.settings.publisher_platforms"
                    :options="FBA.publisherPlatforms"
                    :multiple="true"
                    :close-on-select="true"
                    label="code"
                    track-by="id"
                    placeholder="Publisher Platforms">
                </multiselect>
              </b-field>

              <b-field label="Creative Type">
                <b-select v-model="FBA.settings.creative_type" v-if="'CreativeType' in FBA.facebookAttributes">
                  <option
                      v-for="s in FBA.facebookAttributes['CreativeType'].filter((e) => { return ['DYNAMIC', 'IMAGE_AD'].indexOf(e) > -1})"
                      :value="s" v-bind:key="`creative-type-${s}`">
                    {{ s }}
                  </option>
                </b-select>
              </b-field>

              <b-field label="Call To Action">
                <b-select v-model="FBA.settings.call_to_action">
                  <option v-for="s in FBA.facebookAttributes['CallToAction']" :value="s"
                          v-bind:key="`fba-call-to-action-${s}`">
                    {{ s }}
                  </option>
                </b-select>
              </b-field>

              <b-field :label="`Bid (${FBA.settings.account_currency_code}):`">
                <b-select v-model="FBA.settings.bidding_strategy">
                  <option
                      v-for="option in biddingStrategies['allFacebook']"
                      :key="option.id"
                      :value="option.id">
                    {{ option.code }}
                  </option>
                </b-select>
                <decimal-input
                    v-model="FBA.settings.bid_amount"
                    step="0.01"
                    :ref="'bid_'"
                    :disabled="!isBidHandled(FBA.settings.bidding_strategy)"
                    controls-alignment="right"></decimal-input>
              </b-field>

              <b-field :label="`Budget (${FBA.settings.account_currency_code}):`">
                <decimal-input v-model="FBA.settings.budget_amount"
                               step="1"
                               only-integers
                               controls-alignment="right"></decimal-input>
              </b-field>
            </div>
          </div>
        </div>
        <div v-else-if="networkCode === 'GDN'">
          <div class="columns mt-1">
            <div class="column">

              <b-field label="AdGroup Status">
                <b-select v-model="GDN.settings.ad_group_status">
                  <option v-for="s in GDN.statuses" :value="s.value" v-bind:key="`gdn-ad-group-status-${s.value}`">
                    {{ s.label }}
                  </option>
                </b-select>
              </b-field>

              <b-field label="Device Criteria">
                <multiselect
                    v-model="GDN.settings.device_criteria"
                    :options="GDN.deviceCriteria"
                    :multiple="true"
                    :close-on-select="true"
                    placeholder="Device Criteria"
                    :disable="GDN.deviceCriteria.length === 0"
                    label="type"
                    track-by="id">
                </multiselect>
              </b-field>

              <b-field label="Placement Criteria">
                <multiselect
                    v-model="GDN.settings.placement_criteria"
                    :options="GDN.placementCriteria"
                    :multiple="true"
                    :close-on-select="true"
                    placeholder="Placement Criteria"
                    label="url"
                    track-by="id">
                </multiselect>
              </b-field>

              <b-field :label="`Bid (${GDN.settings.account_currency_code}):`">
                <b-select v-model="GDN.settings.bidding_strategy">
                  <option
                      v-for="option in biddingStrategies['allGDN']"
                      :key="option.id"
                      :value="option.id">
                    {{ option.code }}
                  </option>
                </b-select>
                <decimal-input
                    v-model="GDN.settings.bid_amount"
                    step="0.01"
                    :ref="'bid_'"
                    :disabled="!isBidHandled(GDN.settings.bidding_strategy)"
                    controls-alignment="right"></decimal-input>
              </b-field>

              <b-field :label="`Budget (${GDN.settings.account_currency_code}):`">
                <decimal-input v-model="GDN.settings.budget_amount"
                               step="1"
                               only-integers
                               controls-alignment="right"></decimal-input>
              </b-field>

            </div>
          </div>
        </div>
        <div v-else>
          {{ networkCode }} is not implemented. Notify IT team.
        </div>
      </section>
      <footer class="modal-card-foot">
        <b-button class="is-pulled-right" @click="clear">clear</b-button>
        <b-button class="is-pulled-right" @click="apply(false)">Apply</b-button>
      </footer>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import DecimalInput from "@/components/Element/DecimalInput.vue";
import {creationSettingsService} from "@/services/creation-creation-settings.service";
import {mapState} from "vuex";
import {larryGoogleDeviceCriteriaService} from "@/services/larry-google-device-criteria.service";
import {larryGooglePlacementCriteriaService} from "@/services/larry-google-placement-criteria.service";
import {permissionsUtils} from "@/helpers/permissions-utils";
import Vue from "vue";
import {numberUtils} from "@/helpers/number-utils";
import {markFacebookAttributesService} from "@/services/mark-facebook-attributes.service";
import {markFacebookPublisherPlatformsService} from "@/services/mark-facebook-publisher-platforms.service";
import {markFacebookDeviceCriteriaService} from "@/services/mark-facebook-device-criteria.service";
import {geronimoAttributesService} from "@/services/geronimo-attributes.service";

export default {
  name: 'ExtraSettingsSelector',
  components: {DecimalInput, Multiselect},
  mixins: [
    permissionsUtils,
    numberUtils
  ],
  props: {
    keywordName: {
      type: String,
    },
    agencyFeeds: {
      type: Array,
    },
    countryCode: {
      type: String,
    },
    initialData: {
      type: Object,
    },
    networkCode: {
      type: String,
      default: 'FBA',
      validator: (value) => {
        return [
          'OBN',
          'FBA',
          'GDN'
        ].indexOf(value) >= 0
      }
    },

  },

  mounted() {
    if ('ad_group_status' in this.initialData) {
      this[this.networkCode].settings = this.initialData;
    } else {
      this.fetchSettings();
    }

    if (this.networkCode === 'GDN') {
      if (!this.biddingStrategies.allGDN.length) this.$store.dispatch('biddingStrategies/getAllGDN');
      this.fetchGDNDeviceCriteria();
      this.fetchGDNPlacementCriteria();
    } else if (this.networkCode === 'FBA') {
      if (!this.biddingStrategies.allFacebook.length) this.$store.dispatch('biddingStrategies/getAllFacebook');
      this.fetchFacebookAttributes();
      this.fetchFacebookPublisherPlatforms();
      this.fetchFacebookDeviceCriteria();
    } else if (this.networkCode === 'OBN') {
      if (!this.biddingStrategies.allOBN.length) this.$store.dispatch('biddingStrategies/getAllOBN');
      this.fetchOutbrainDeviceCriteria();
    }

  },
  data() {
    return {

      isLoading: false,

      countrySetting: {},

      OBN: {

        statuses: [
          {'label': 'ACTIVE', 'value': 'ENABLED'},
          {'label': 'PAUSED', 'value': 'PAUSED'},
        ],

        deviceCriteria: [],

        settings: {
          campaign_status: null,
          device_criteria: [],
          account_currency_code: null,
          bidding_strategy: null,
          bid_amount: null,
          budget_amount: null,
        }
      },

      FBA: {
        facebookAttributes: {},
        statuses: [
          {'label': 'ACTIVE', 'value': 'ACTIVE'},
          {'label': 'PAUSED', 'value': 'PAUSED'},
        ],
        deviceCriteria: [],
        publisherPlatforms: [],
        settings: {
          adset_status: true,
          min_age: 0,
          max_age: 0,
          delivery_method: null,
          call_to_action: null,
          deviceCriteriaHasError: false,
          device_criteria: [],
          publisher_platforms: [],
          publisherPlatformsHasError: false,
          creative_type: null,
          account_currency_code: null,
          bidding_strategy: null,
          bid_amount: null,
          budget_amount: null,
        }
      },

      GDN: {
        statuses: [
          {'label': 'ACTIVE', 'value': 'ENABLED'},
          {'label': 'PAUSED', 'value': 'PAUSED'},
        ],
        placementCriteria: [],
        deviceCriteria: [],

        settings: {
          ad_group_status: true,
          device_criteria: [],
          placement_criteria: [],
          bid_amount: 0.0,
          bidding_strategy: null,
          budget_amount: null,
          account_currency_code: null,
          bidding_strategy_code: null
        }
      }
    }
  },
  computed: {
    ...mapState([
      'biddingStrategies',
      'networks',
      'users',
      'theme',
      'countries',
      'currencies',
    ]),
  },
  watch: {},
  methods: {

    apply(ignoreValidation) {
      ignoreValidation = ignoreValidation || false;
      if (!ignoreValidation && !this.validate()) {
        return;
      }
      this[this.networkCode].settings.bidding_strategy_code = this.biddingStrategyDetails(this[this.networkCode].settings.bidding_strategy)?.code;
      this.$emit('input', this[this.networkCode].settings)
      this.$emit('close', []);
    },
    clear() {
      this[this.networkCode].settings = {};
      this.apply(true);
    },
    validate() {

      if (this.networkCode === 'GDN') {

        if (this.makeNumber(this.GDN.settings.bid_amount) === 0 && this.isBidHandled(this.GDN.settings.bidding_strategy)) {
          this.$buefy.snackbar.open({'message':'Bid must be provided for this bidding strategy.', 'position': 'is-top'});
          return false;
        }

        if (this.makeNumber(this.GDN.settings.bid_amount) !== 0 && this.isBidHandled(this.GDN.settings.bidding_strategy)) {
          let bidAmountParts = this.GDN.settings.bid_amount.toString().split('.');

          if (bidAmountParts.length > 1 && bidAmountParts[1].length > 2) {
            this.$buefy.snackbar.open({'message':'Bid amount decimals must be maximum 2 places, for example: 0.01', 'position': 'is-top'});
            return false;
          }
        }

        if (this.GDN.settings.device_criteria.length === 0) {
          this.$buefy.snackbar.open({'message':'You must select at least one device.', 'position': 'is-top'});
          return false;
        }

        if (this.GDN.settings.placement_criteria.length === 0) {
          this.$buefy.snackbar.open({'message':'You must select at least one placement.', 'position': 'is-top'});
          return false;
        }

        if (this.makeNumber(this.GDN.settings.budget_amount) <= 1) {
          this.$buefy.snackbar.open({'message':'Budget must be provided, at least 1$', 'position': 'is-top'});
          return false;
        }

      } else if (this.networkCode === 'FBA') {

        if (this.makeNumber(this.FBA.settings.min_age) > this.makeNumber(this.FBA.settings.max_age)) {
          this.$buefy.snackbar.open({'message':'Min age cannot be greater than max age.', 'position': 'is-top'});
          return false;
        }

        if (this.makeNumber(this.FBA.settings.min_age) === 0 ||
            this.makeNumber(this.FBA.settings.max_age) === 0) {
          this.$buefy.snackbar.open({'message':'Min age and max age are required fields.', 'position': 'is-top'});
          return false;
        }

        if (this.makeNumber(this.FBA.settings.max_age) > 65) {
          this.$buefy.snackbar.open({'message':'Max age cannot be greater than 65 years old.', 'position': 'is-top'});
          return false;
        }
        if (this.FBA.settings.publisher_platforms.length === 0) {
          this.$buefy.snackbar.open({'message':'Select at least one publisher.', 'position': 'is-top'});
          return false;
        }
        if (this.FBA.settings.device_criteria.length === 0) {
          this.$buefy.snackbar.open({'message':'Select at least one device.', 'position': 'is-top'});
          return false;
        }

        if (this.makeNumber(this.FBA.settings.bid_amount) === 0 && this.isBidHandled(this.FBA.settings.bidding_strategy)) {
          this.$buefy.snackbar.open({'message':'Bid must be provided for this bidding strategy.', 'position': 'is-top'});
          return false;
        }

        if (this.makeNumber(this.FBA.settings.bid_amount) !== 0 && this.isBidHandled(this.FBA.settings.bidding_strategy)) {
          let bidAmountParts = this.FBA.settings.bid_amount.toString().split('.');

          if (bidAmountParts.length > 1 && bidAmountParts[1].length > 2) {
            this.$buefy.snackbar.open({'message':'Bid amount decimals must be maximum 2 places, for example: 0.01.', 'position': 'is-top'});
            return false;
          }
        }

        if (this.makeNumber(this.FBA.settings.budget_amount) < 3) {
          this.$buefy.snackbar.open({'message':'Budget must be provided, at least 3$.', 'position': 'is-top'});
          return false;
        }

      } else if (this.networkCode === 'OBN') {

        if (this.OBN.settings.device_criteria.length === 0) {
          this.$buefy.snackbar.open({'message':'Select at least one device.', 'position': 'is-top'});
          return false;
        }

        if (this.makeNumber(this.OBN.settings.bid_amount) === 0 && this.isBidHandled(this.OBN.settings.bidding_strategy)) {
          this.$buefy.snackbar.open({'message':'Bid must be provided for this bidding strategy.', 'position': 'is-top'});
          return false;
        }

        if (this.makeNumber(this.OBN.settings.bid_amount) !== 0 && this.isBidHandled(this.OBN.settings.bidding_strategy)) {
          let bidAmountParts = this.OBN.settings.bid_amount.toString().split('.');

          if (bidAmountParts.length > 1 && bidAmountParts[1].length > 2) {
            this.$buefy.snackbar.open({'message':'Bid amount decimals must be maximum 2 places, for example: 0.01', 'position': 'is-top'});
            return false;
          }
        }

        if (this.makeNumber(this.OBN.settings.budget_amount) < 20) {
          this.$buefy.snackbar.open({'message':'Budget must be provided, at least 20$.', 'position': 'is-top'});
          return false;
        }

      }

      return true;
    },

    isBidHandled(biddingStrategyId) {
      return this.biddingStrategyDetails(biddingStrategyId)?.is_bid_handled;
    },

    biddingStrategyDetails: function (biddingStrategyId) {
      let r;

      if (this.networkCode === 'GDN') {
        r = this.$store.state.biddingStrategies.allGDN.filter((row) => (row.id === biddingStrategyId || row.code === biddingStrategyId));
      } else if (this.networkCode === 'FBA') {
        r = this.$store.state.biddingStrategies.allFacebook.filter((row) => (row.id === biddingStrategyId || row.code === biddingStrategyId));
      } else if (this.networkCode === 'OBN') {
        r = this.$store.state.biddingStrategies.allOBN.filter((row) => (row.id === biddingStrategyId || row.code === biddingStrategyId));
      }

      return r[0];
    },

    fetchSettings() {
      let that = this,
          userId = that.theme.userId;

      if (this.isSuperUser()) {
        // AUTOMATION
        userId = '2';
      }

      this.isLoading = true;
      creationSettingsService.get({
        'user': userId,
        'network__code': this.networkCode,
        'agency_feed__in': this.agencyFeeds.join(',')
      }).then(response => {
        that.isLoading = false;
        if (response.data['results'].length === 0) {
          this.$buefy.snackbar.open({'message':'No configurations found for this user, please try another one.', 'position': 'is-top'});
          return;
        }
        that.populateForm(response.data['results'])
      }).catch(r => {
        this.$buefy.snackbar.open({'message':'An Error Occurred.', 'position': 'is-top'});
        console.dir(r);
      })
    },

    populateForm(data) {

      for (let i in data) {
        if (typeof (data[i]['json_value']['country_settings'][this.countryCode]) !== 'undefined') {
          this.countrySetting = data[i]['json_value']['country_settings'][this.countryCode];

          if (this.networkCode === 'GDN') {
            this.GDN.settings.budget_amount = this.countrySetting.budget_amount;
            this.GDN.settings.bid_amount = this.countrySetting.bid_amount;
            this.GDN.settings.account_currency_code = this.countrySetting.account_currency_code;
            this.GDN.settings.bidding_strategy = this.biddingStrategyDetails(this.countrySetting.bidding_strategy_code)?.id;
            this.GDN.settings.ad_group_status = data[i]['json_value']['adgroup_status'];
            this.GDN.settings.device_criteria = data[i]['json_value']['device_criteria'];
            this.GDN.settings.placement_criteria = data[i]['json_value']['placement_criteria'];
            this.setObjectMultiselectGDNDeviceCriteria()
            this.setObjectMultiselectGDNPlacementCriteria()
            break;
          } else if (this.networkCode === 'FBA') {
            this.FBA.settings.budget_amount = this.countrySetting.budget_amount;
            this.FBA.settings.bid_amount = this.countrySetting.bid_amount;
            this.FBA.settings.account_currency_code = this.countrySetting.account_currency_code;
            this.FBA.settings.bidding_strategy = this.biddingStrategyDetails(this.countrySetting.bidding_strategy_code)?.id;

            this.FBA.settings.adset_status = data[i]['json_value']['adset_status'];
            this.FBA.settings.delivery_method = data[i]['json_value']['delivery_method'];
            this.FBA.settings.min_age = data[i]['json_value']['min_age'];
            this.FBA.settings.max_age = data[i]['json_value']['max_age'];
            this.FBA.settings.creative_type = data[i]['json_value']['creative_type'];
            this.FBA.settings.call_to_action = data[i]['json_value']['call_to_action'];
            this.FBA.settings.device_criteria = data[i]['json_value']['device_criteria'];
            this.FBA.settings.publisher_platforms = data[i]['json_value']['publisher_platforms'];
            this.setObjectMultiselectFacebookDeviceCriteria();
            this.setObjectMultiselectFacebookPublisherPlatforms();
            break;
          }else if (this.networkCode === 'OBN') {
            this.OBN.settings.budget_amount = this.countrySetting.budget_amount;
            this.OBN.settings.bid_amount = this.countrySetting.bid_amount;
            this.OBN.settings.account_currency_code = this.countrySetting.account_currency_code;
            this.OBN.settings.bidding_strategy = this.biddingStrategyDetails(this.countrySetting.bidding_strategy_code)?.id;

            this.OBN.settings.campaign_status = data[i]['json_value']['campaign_status'];
            this.OBN.settings.device_criteria = data[i]['json_value']['device_criteria'];
            // this.setObjectMultiselectFacebookDeviceCriteria();
            break;
          }

        }
      }
    },

    fetchGDNDeviceCriteria() {
      let that = this;
      larryGoogleDeviceCriteriaService.get().then(response => {
        that.GDN.deviceCriteria = response.data['results']
        that.setObjectMultiselectGDNDeviceCriteria()
      })
    },

    fetchGDNPlacementCriteria() {
      let that = this;
      larryGooglePlacementCriteriaService.get().then(response => {
        that.GDN.placementCriteria = response.data['results']
        that.setObjectMultiselectGDNPlacementCriteria()
      })
    },

    setObjectMultiselectGDNDeviceCriteria() {
      if (this.GDN.deviceCriteria.length === 0) {
        return;
      }

      let indexed = {};

      for (let i in this.GDN.deviceCriteria) {
        indexed['id_' + this.GDN.deviceCriteria[i]['id']] = this.GDN.deviceCriteria[i];
      }

      for (let i in this.GDN.settings.device_criteria) {
        let row = this.GDN.settings.device_criteria[i];

        if (typeof row === "object") {
          continue
        }

        Vue.set(this.GDN.settings.device_criteria, i, indexed['id_' + row]);
      }

    },
    setObjectMultiselectGDNPlacementCriteria() {

      if (this.GDN.placementCriteria.length === 0) {
        return;
      }

      let indexed = {};

      for (let i in this.GDN.placementCriteria) {
        indexed['id_' + this.GDN.placementCriteria[i]['id']] = this.GDN.placementCriteria[i];
      }

      for (let i in this.GDN.settings.placement_criteria) {
        let row = this.GDN.settings.placement_criteria[i];

        if (typeof row === "object") {
          continue
        }

        Vue.set(this.GDN.settings.placement_criteria, i, indexed['id_' + row]);
      }

    },
    fetchFacebookAttributes() {
      let that = this;

      markFacebookAttributesService.get()
          .then(response => {
            that.FBA.facebookAttributes = response.data
          })

    },
    setObjectMultiselectFacebookDeviceCriteria() {

      if (this.FBA.deviceCriteria.length === 0) {
        return;
      }

      let indexed = {};

      for (let i in this.FBA.deviceCriteria) {
        indexed['id_' + this.FBA.deviceCriteria[i]['id']] = this.FBA.deviceCriteria[i];
      }

      for (let i in this.FBA.settings.device_criteria) {
        let row = this.FBA.settings.device_criteria[i];

        if (typeof row === "object") {
          continue
        }

        Vue.set(this.FBA.settings.device_criteria, i, indexed['id_' + row]);
      }

    },

    setObjectMultiselectFacebookPublisherPlatforms() {

      if (this.FBA.publisherPlatforms.length === 0) {
        return;
      }

      let indexed = {};

      for (let i in this.FBA.publisherPlatforms) {
        indexed['id_' + this.FBA.publisherPlatforms[i]['id']] = this.FBA.publisherPlatforms[i];
      }

      for (let i in this.FBA.settings.publisher_platforms) {
        let row = this.FBA.settings.publisher_platforms[i];

        if (typeof row === "object") {
          continue
        }

        Vue.set(this.FBA.settings.publisher_platforms, i, indexed['id_' + row]);
      }

    },

    fetchFacebookPublisherPlatforms() {
      let that = this;

      markFacebookPublisherPlatformsService.get().then(response => {
        that.FBA.publisherPlatforms = response.data['results']
        that.setObjectMultiselectFacebookPublisherPlatforms();
      })
    },

    fetchFacebookDeviceCriteria() {
      let that = this;

      markFacebookDeviceCriteriaService.get().then(response => {
        that.FBA.deviceCriteria = response.data['results']
        that.setObjectMultiselectFacebookDeviceCriteria();
      })
    },

    fetchOutbrainDeviceCriteria() {
      let that = this;

      geronimoAttributesService.getAll().then(response => {
        that.OBN.deviceCriteria = response.data['Platform']
        // that.setObjectMultiselectDeviceCriteria()
      })
    },

  },
}
</script>
