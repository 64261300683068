import { config } from './config';
import axios from "axios";


export const devCountriesService = {
    getAll,
    getAllFullList,
    config
};


export function getAll(filters) {

    let axiosConfig = {}

    axiosConfig['params'] = filters || {};

    return new Promise((resolve, reject) => {
      axios
        .get(`${config.apiUrl}/api/dev/countries/?subdomainlocale__isnull=False`, axiosConfig)
        .then(response => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
}
export function getAllFullList() {

    return new Promise((resolve, reject) => {
      axios
        .get(`${config.apiUrl}/api/dev/countries/`)
        .then(response => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
}
